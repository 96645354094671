import { Injectable } from '@angular/core';
import * as L from "leaflet"
import { SystemOverviewLayersService } from '@app/pages/system-overview/services/system-overview-layers.service'

@Injectable({
  providedIn: 'root'
})
export class SystemOverviewMapService {

  constructor(
    private layersService: SystemOverviewLayersService) { }

  public readonly overlays = {
    'tunnel': L.layerGroup([
      this.layersService.tunnel,
      this.layersService.tunnelLable
    ]),
    'rain inflows': L.layerGroup([
      this.layersService.rainInflow,
      this.layersService.rainClouds,
    ]),
    'snow inflows': L.layerGroup([
      this.layersService.snowInflow,
      this.layersService.snowClouds,
    ]),
    'lakes & reservoirs': L.layerGroup([
      this.layersService.lakeLables,
      this.layersService.lakes
    ]),
    'rivers': L.layerGroup([
      this.layersService.rivers,
      this.layersService.riverLables
    ]),

    'creeks': this.layersService.creeks,
    'power facilities & dams': L.layerGroup([
      this.layersService.buildings,
      this.layersService.buildingLables
    ]),
    'districts': L.layerGroup([
      this.layersService.citylLable
    ])
  }

  public readonly options: L.MapOptions = {
    crs: L.CRS.Simple,
    zoomControl: false,
    layers: [
      L.tileLayer('../../assets/tiles/system-overview/regular/{z}/{x}/{y}.png', {
        maxZoom: 6,
        minZoom: 3.6
      })
    ],
    zoom: 3.75,
    zoomSnap: 0,
    zoomDelta: 0.5,
    center: L.latLng(-44, 80),
  }

  public readonly baseLayers = {
    'map': L.tileLayer('../../assets/tiles/system-overview/regular/{z}/{x}/{y}.png', {
      maxZoom: 6,
      minZoom: 3.6
    }),
    'snow': L.layerGroup([
      this.layersService.snow,
      L.tileLayer('../../assets/system-overview/winter-tiles/{z}/{x}/{y}.png', {
        maxZoom: 6,
        minZoom: 3.6
      })
    ]) 
  }

}
