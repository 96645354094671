<ng-sidebar-container>
  <ng-sidebar
    #sidebar
    [(opened)]="opened"
    mode="over"
    dock="true"
    autoCollapseWidth="500"
  >
    <div class="sidebar">
      <perfect-scrollbar>
        <app-header></app-header>
        <div class="sliders">
          <app-adjustable-slider
            class="inflow-slider"
            [slider]="inflowSlider$ | async"
            [title]="'Inflow (m3/s)'"
            (onOverUpperThresholdStatusChanges)="
              onOverUpperThresholdStatusChanges($event)
            "
          >
          </app-adjustable-slider>
          <app-level-slider class="level-slider" [title]="'Level (m)'">
          </app-level-slider>
        </div>
        <app-footer></app-footer>
      </perfect-scrollbar>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <app-page-name [name]="pageTitle"></app-page-name>
    <perfect-scrollbar>
      <div class="main-content">
        <div>
          <div
            class="map"
            leaflet
            [leafletOptions]="options"
            (leafletMapReady)="onMapLoaded($event)"
          ></div>
          <div class="bottom-bar">
            <app-options></app-options>
            <button
              (click)="reset()"
              type="button"
              class="button button-large reset"
            >
              <fa-icon [icon]="faUndo" size="sm"></fa-icon>
            </button>
          </div>
        </div>

        <div class="right-sidebar">
          <h3>Inflow Period</h3>
          <div class="inflow-period-table">
            <div
              *ngFor="let period of inflowPeriodTableDate"
              class="content"
              [class.active]="period === (selectedPeriod$ | async)"
              (click)="onPeriodButtonsClick(period)"
            >
              <span>{{ period }}</span>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</ng-sidebar-container>
