import { Component, ElementRef, ViewChild } from "@angular/core";
import { faUndo, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { PowerPlantSystemModelService } from "@services/power-plant-system-model.service";

@Component({
  selector: "app-power-plant-system-model",
  templateUrl: "./power-plant-system-model.component.html",
  styleUrls: ["./power-plant-system-model.component.scss"],
})
export class PowerPlantSystemModelComponent {
  public pageTitle = "Power Plant System Model";

  @ViewChild("rendererCanvas", { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;
  public headworksItems: Array<any>;
  public surgeItems: Array<any>;
  public faChevronLeft = faChevronLeft;

  faUndo = faUndo;

  public constructor(public powerhouseService: PowerPlantSystemModelService) {
    this.headworksItems = this.powerhouseService.getItems("headworks");
    this.surgeItems = this.powerhouseService.getItems("surge");
  }

  public ngOnInit(): void {
    this.powerhouseService.createScene(this.rendererCanvas);
    this.powerhouseService.animate();
  }

  public toggleItem(item) {
    item.selected = !item.selected;
    this.powerhouseService.toggleLabelSprites(item);
  }

  resetModelScene = () => {
    [...this.headworksItems, ...this.surgeItems].forEach((item) => {
      if (item.selected) {
        item.selected = false;
        this.powerhouseService.toggleLabelSprites(item);
      }
      
    });
    this.powerhouseService.resetModelSceneState();
    this.powerhouseService.displayWaterWhenReset();
  };
}
