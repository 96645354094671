<div class="row">
  <div class="col-md-2">
    <a
      class="ts-logo"
      href="https://truescape.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="../../assets/logos/truescape-logo-light.png"
        alt="Truescape Logo"
      />
    </a>
  </div>
</div>
