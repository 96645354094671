<button
  type="button"
  class="close pull-right"
  aria-label="Close"
  (click)="modalRef.hide()"
  data-cy="modal-close-btn"
>
  <span aria-hidden="true">&times;</span>
</button>
<div class="content">
  <img
    alt="Downstream"
    class="downstream-image"
    src="../../../assets/images/aoi/{{ currentImage }}"
  />
  <div class="compare" (click)="compareRiverFlows()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-chevron-left"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
    <span>compare</span>
  </div>
  <div class="banner" [style.background]="bannerColor">
    <span class="river-name">{{bannerTitle}}</span>
    <span class="risk-level" [style.color]="bannerColor">{{
      bannerMessage
    }}</span>
  </div>
</div>

<div *ngIf="displaySidePanel" class="side-panel">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    (click)="closeSidePanel()"
  >
    <circle cx="12" cy="12" r="12" fill="#10a3c8" />
    <path
      d="M10.5 7L15.5 12L10.5 17"
      stroke="#fff"
      stroke-width="2.08333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <div>
    <h4>Downstream Risk</h4>
    <div>
      <div *ngFor="let item of sidePanelImages">
        <img alt="Downstream" class="downstream-image" [src]="item.imagePath" />
        <div class="banner" [style.background]="item.bannerColor">
          <span class="risk-level" [style.color]="item.bannerColor">{{
            item.bannerText
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
