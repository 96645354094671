import * as THREE from "three";
import { ElementRef, Injectable, NgZone, OnDestroy } from "@angular/core";
import {
  initialCameraTweenTo,
  ModelInternalData,
} from "@app/pages/power-plant-system-model/data/data";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Subject } from "rxjs/internal/Subject";
import * as TWEEN from "@tweenjs/tween.js";
import { TweenLite, Linear } from "gsap";

@Injectable({ providedIn: "root" })
export class PowerPlantSystemModelService implements OnDestroy {
  #canvas: HTMLCanvasElement;
  #renderer: THREE.WebGLRenderer;
  #camera: THREE.PerspectiveCamera;
  #scene: THREE.Scene;
  #light: THREE.AmbientLight;
  #controls: OrbitControls;
  #powerHouseModel: THREE.Object3D;
  #loadManager: THREE.LoadingManager;
  #frameId: number = null;

  public isModelLoading: boolean = false;
  public progressLoader = new Subject<number>();

  private meshesInitialColor = {};

  public constructor(private ngZone: NgZone) {}

  public ngOnDestroy(): void {
    if (this.#frameId != null) {
      cancelAnimationFrame(this.#frameId);
    }
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    this.#canvas = canvas.nativeElement;

    this.#renderer = new THREE.WebGLRenderer({
      canvas: this.#canvas,
      alpha: true, // transparent background
      antialias: true, // smooth edges
    });
    this.#renderer.shadowMap.enabled = true;
    this.#renderer.setSize(window.innerWidth, window.innerHeight);

    this.#renderer.toneMappingExposure = 1;
    this.#renderer.outputEncoding = THREE.sRGBEncoding;

    // create the scene
    this.#scene = new THREE.Scene();

    this.#camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    this.#camera.position.set(
      initialCameraTweenTo.x,
      initialCameraTweenTo.y,
      initialCameraTweenTo.z
    );

    this.#scene.add(this.#camera);

    this.#setControls();
    this.#setLights();
    this.#powerhouse1modelLoader();
  }

  #helpersOnScreen = () => {
    // The X axis is red. The Y axis is green. The Z axis is blue.
    const axesHelper = new THREE.AxesHelper(100);
    axesHelper.position.set(0, 0, 0);
    this.#scene.add(axesHelper);

    const gridHelper = new THREE.GridHelper(200, 200);
    this.#scene.add(gridHelper);

    const box = new THREE.BoxHelper(this.#powerHouseModel, 0xffff00);
    this.#scene.add(box);
  };

  #setControls = () => {
    this.#controls = new OrbitControls(this.#camera, this.#renderer.domElement);
    this.#controls.maxDistance = 200;
  };

  #setLights = () => {
    const ambient = new THREE.AmbientLight(0xffffff, 0.5);
    this.#scene.add(ambient);

    const directional = new THREE.DirectionalLight(0xffffff);
    directional.position.set(0, 1, 0);
    directional.castShadow = true;
    directional.shadow.mapSize.width = 512; // default
    directional.shadow.mapSize.height = 512; // default
    directional.shadow.camera.near = 0.5; // default
    directional.shadow.camera.far = 500; // default
    this.#scene.add(directional);
  };

  resetModelSceneState = () => {
    TWEEN.removeAll();

    new TWEEN.Tween(this.#camera.position)
      .to(
        {
          x: initialCameraTweenTo.x,
          y: initialCameraTweenTo.y,
          z: initialCameraTweenTo.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onStart((_) => {
        new TWEEN.Tween(this.#controls.target)
          .to(
            {
              x: 0,
              y: 0,
              z: 0,
            },
            1000
          )
          .easing(TWEEN.Easing.Quadratic.InOut)
          .start();
        this.#controls.enabled = false;
      })
      .onUpdate((_) => {
        this.#controls.update();
        this.#camera.updateProjectionMatrix();
        this.#camera.updateMatrix();
      })
      .onComplete((_) => {
        this.#controls.update();
        this.#controls.enabled = true;
      })
      .start();
  };

  #powerhouse1modelLoader = () => {
    // load terrain models
    this.isModelLoading = true;
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("assets/draco/");

    this.#loadManager = new THREE.LoadingManager();
    const loader = new GLTFLoader(this.#loadManager).setPath("assets/models/");
    loader.setDRACOLoader(dracoLoader);
    loader.load("Headworks.gltf", (glb) => {
      this.centerModel(glb);
      this.setMeshesInitialColor(glb.scene);
      this.#powerHouseModel = glb.scene;
      this.#scene.add(this.#powerHouseModel);
    });
    this.#loadManager.onLoad = () => {
      this.isModelLoading = false;
    };

    this.#loadManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      const progress = itemsLoaded / itemsTotal;
      this.progressLoader.next(progress);
    };
  };

  private centerModel(glb: any) {
    const box = new THREE.Box3().setFromObject(glb.scene);
    const center = box.getCenter(new THREE.Vector3());

    glb.scene.position.x += glb.scene.position.x - center.x;
    glb.scene.position.y += glb.scene.position.y - center.y;
    glb.scene.position.z += glb.scene.position.z - center.z;
  }

  private setMeshesInitialColor(group: THREE.Object3D) {
    group.children.forEach((child) => {
      this.meshesInitialColor[child.name] = new THREE.Color(
        child.material.color.getHex()
      );
    });
  }

  toggleModelLeftSide = (isShowing) => {
    this.#scene.getObjectByName("left-elements").visible = isShowing;
  };

  #tweenToSelectedObject = (item) => {
    TWEEN.removeAll();

    new TWEEN.Tween(this.#camera.position)
      .to(
        {
          x: item.cameraTweenTo.x,
          y: item.cameraTweenTo.y,
          z: item.cameraTweenTo.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onStart((_) => {
        const position = this.#scene.getObjectByName(item.modelName).position;
        new TWEEN.Tween(this.#controls.target)
          .to(
            {
              x: item.controlTweenTo.x,
              y: item.controlTweenTo.y,
              z: item.controlTweenTo.z,
            },
            1000
          )
          .easing(TWEEN.Easing.Quadratic.InOut)
          .start();

        this.#controls.enabled = false;
      })
      .onUpdate((_) => {
        this.#controls.update();
        this.#camera.updateProjectionMatrix();
        this.#camera.updateMatrix();
      })
      .onComplete((_) => {
        this.#controls.update();
        this.#controls.enabled = true;
      })
      .start();
  };

  toggleLabelSprites = (item) => {
    if (!item.selected) {
      const addedSprite = this.#scene.getObjectByName(item.sprite);
      this.#scene.getObjectByName(item.modelName).remove(addedSprite);
      return;
    }
    this.toggleWaterWhenStoplogsOrAditIsOn(item);

    this.#tweenToSelectedObject(item);
    const sprite = this.getLabelSprite(item);

    this.#scene.getObjectByName(item.modelName).add(sprite);

    this.highlightObject(item.modelName);
  };

  private toggleWaterWhenStoplogsOrAditIsOn(item) {
    if (["HeadWorks_Screen", "HeadWorks_Block"].includes(item.modelName)) {
      this.#scene.getObjectByName("Water").visible = false;
    }
  }

  public displayWaterWhenReset() {
    this.#scene.getObjectByName("Water").visible = true;
  }

  private getLabelSprite(item): THREE.Sprite {
    const canvas = document.createElement("canvas");

    canvas.width = 800;
    canvas.height = 800;

    const context = canvas.getContext("2d");

    context.textBaseline = "middle";
    context.textAlign = "center";
    context.shadowBlur = 0;
    context.fillStyle = "#fff";
    context.font = "28px Arial, sans-serif";
    context.strokeText(item.title, 400, 400);
    context.fillText(item.title, 400, 400);

    const map = new THREE.Texture(canvas);
    map.needsUpdate = true;

    const marker = new THREE.SpriteMaterial({
      map: map,
      transparent: true,
      sizeAttenuation: false,
      color: 0xffffff,
    });

    const sprite = new THREE.Sprite(marker);
    sprite.position.set(item.positionX, item.positionY, item.positionZ || 0);
    sprite.name = item.sprite;
    return sprite;
  }

  private highlightObject(objectName: string) {
    this.#scene.getObjectByName(objectName).traverse((child) => {
      const target = <any>child;
      if (target.isMesh) {
        const initialColor = this.meshesInitialColor[objectName];
        target.material = target.material.clone();
        const initial = new THREE.Color(target.material.color.getHex());
        const color = new THREE.Color(0xffff00);
        target.material.emissive.set(color);
        TweenLite.to(initial, 1, {
          r: color.r,
          g: color.g,
          b: color.b,
          repeat: 3,
          yoyo: true,
          ease: Linear.easeInOut,
          onUpdate: () => {
            (<any>target).material.color = initial;
            target.material.emissive.set(initial);
          },
          onComplete: () => {
            (<any>target).material.color = initialColor;
            target.material.emissive.set(initialColor);
          },
        });
      }
    });
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== "loading") {
        this.render();
      } else {
        window.addEventListener("DOMContentLoaded", () => {
          this.render();
        });
      }

      window.addEventListener("resize", () => {
        this.resize();
      });
    });
  }

  public render(): void {
    this.#frameId = requestAnimationFrame(() => {
      this.render();
      TWEEN.update();
    });

    this.#renderer.render(this.#scene, this.#camera);
  }

  public resize(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.#camera.aspect = width / height;
    this.#camera.updateProjectionMatrix();

    this.#renderer.setSize(width, height);
  }

  public getItems(category) {
    return ModelInternalData[category].items;
  }
}
