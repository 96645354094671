
import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
  stagger
} from '@angular/animations'

export const listAnimation = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0, transform: 'translateY(-50%)' }),
      stagger(80, [
        animate(200, style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ], { optional: true })
  ])
])