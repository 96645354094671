import { Injectable } from "@angular/core";
import * as L from "leaflet";
import { Layer, LayerGroup } from "leaflet";
import { Lables, LablesType } from "@app/data/lables";
import { RainInflowPaths, SnowInflowPaths } from "@data/inflows";
import { FlowsService } from "@app/shared/services/flows-overlay.service";
import { LabelOverlayService } from "@app/shared/services/label-overlay.service";
import { ArrowFlowPoints, NormalFlowAnimationBegins, NormalFlowSpeed, SmallFlowSize } from "@app/data/flow-type";

@Injectable({
  providedIn: "root",
})
export class SystemOverviewLayersService {
  constructor(
    private flowsService: FlowsService,
    private labelOverlayService: LabelOverlayService
  ) {}

  private readonly _rainInflowSvgElement = this.flowsService.getFlows(
    "#10A3C8",
    RainInflowPaths,
    SmallFlowSize,
    ArrowFlowPoints,
    NormalFlowSpeed,
    NormalFlowAnimationBegins
  );
  private readonly _snowInflowSvgElement = this.flowsService.getFlows(
    "#FFF",
    SnowInflowPaths,
    SmallFlowSize,
    ArrowFlowPoints,
    NormalFlowSpeed,
    NormalFlowAnimationBegins
  );

  public readonly lakes: Layer = L.imageOverlay(
    "../../assets/layers/lakes.svg",
    [
      [0, 0],
      [-88, 160],
    ]
  );
  public readonly rivers: Layer = L.imageOverlay(
    "../../assets/layers/rivers.svg",
    [
      [0, 0],
      [-88, 160],
    ]
  );
  public readonly creeks: Layer = L.imageOverlay(
    "../../assets/layers/creeks.svg",
    [
      [0, 0],
      [-88, 160],
    ]
  );

  public readonly rainInflow: Layer = L.svgOverlay(this._rainInflowSvgElement, [
    [0, 0],
    [-88, 160],
  ]);
  public readonly snowInflow: Layer = L.svgOverlay(this._snowInflowSvgElement, [
    [0, 0],
    [-88, 160],
  ]);

  public readonly tunnel: Layer = L.imageOverlay(
    "../../assets/layers/tunnel.svg",
    [
      [0, 0],
      [-88, 160],
    ]
  );

  public readonly buildings: LayerGroup = L.layerGroup([
    L.imageOverlay(
      "../../assets/layers/buildings/buildings-fullscreen/alouette-dam.svg",
      [
        [0, 0],
        [-88, 160],
      ]
    ),
    L.imageOverlay(
      "./../assets/layers/buildings/buildings-fullscreen/ruskin-dam.svg",
      [
        [0, 0],
        [-88, 160],
      ]
    ),
    L.imageOverlay(
      "./../assets/layers/buildings/buildings-fullscreen/stave-falls-dam.svg",
      [
        [0, 0],
        [-88, 160],
      ]
    ),
    L.imageOverlay(
      "../../assets/layers/buildings/buildings-fullscreen/alouette-powerhouse.svg",
      [
        [0, 0],
        [-88, 160],
      ]
    ),
  ]);

  private webmOptions = { className: "webm" };
  //videos
  public readonly snow = L.videoOverlay(
    "../../assets/webm/snow.webm",
    [
      [0, 0],
      [-88, 160],
    ],
    this.webmOptions
  );

  private snowVideoPath = "../../assets/webm/snow-cloud.webm";

  public readonly snowClouds: LayerGroup = L.layerGroup([
    L.videoOverlay(
      this.snowVideoPath,
      [
        [-16.202946941912074, 54.33190006668681],
        [-22.96656240849005, 65.85234399327567],
      ],
      this.webmOptions
    ), //top left
    L.videoOverlay(
      this.snowVideoPath,
      [
        [-27.426089089750253, 69.94024345109753],
        [-40.73034368884319, 86.29184128238494],
      ],
      this.webmOptions
    ), //middle
    L.videoOverlay(
      this.snowVideoPath,
      [
        [-44.89256859135271, 113.49495403807218],
        [-59.68333208419906, 131.779013431239],
      ],
      this.webmOptions
    ), //bottom right
    L.videoOverlay(
      this.snowVideoPath,
      [
        [-9.810958698772447, 80.86608382018503],
        [-17.243503167539455, 95.43387097896836],
      ],
      this.webmOptions
    ), //top middle
    L.videoOverlay(
      this.snowVideoPath,
      [
        [-9.439331475334098, 111.78546881025576],
        [-18.507035727229844, 128.28571753091853],
      ],
      this.webmOptions
    ), //top right
  ]);

  private rainVideoPath = "../../assets/webm/rain-cloud.webm";

  public readonly rainClouds: LayerGroup = L.layerGroup([
    L.videoOverlay(
      this.rainVideoPath,
      [
        [-9.810958698772447, 61.61579364607848],
        [-17.169177722851785, 74.69707191110841],
      ],
      this.webmOptions
    ), //top left
    L.videoOverlay(
      this.rainVideoPath,
      [
        [-23.93279318942976, 64.8117877676483],
        [-41.02764546759387, 88.89323184645339],
      ],
      this.webmOptions
    ), //middle
    L.videoOverlay(
      this.rainVideoPath,
      [
        [-37.68300045664872, 110.81923802931605],
        [-53.51432017512244, 128.21139208623086],
      ],
      this.webmOptions
    ), //bottom right
    L.videoOverlay(
      this.rainVideoPath,
      [
        [-13.00695282034226, 69.34563989359617],
        [-22.66926062973937, 87.48104839738767],
      ],
      this.webmOptions
    ), //top middle
    L.videoOverlay(
      this.rainVideoPath,
      [
        [-10.9258403690875, 110.52193625056537],
        [-23.70981685536675, 127.54246308404183],
      ],
      this.webmOptions
    ), //top right
  ]);

  private readonly _riverLables: LablesType = new Lables().Rivers;
  private readonly _lakeLables: LablesType = new Lables().Lakes;
  private readonly _buildingLables: LablesType = new Lables().Buildings;
  public readonly _tunnelLable: LablesType = new Lables().Tunnel;
  public readonly _citylLable: LablesType = new Lables().Districts;

  public get riverLables(): LayerGroup {
    return this.labelOverlayService.getGroupLables(this._riverLables);
  }

  public get lakeLables(): LayerGroup {
    return this.labelOverlayService.getGroupLables(this._lakeLables);
  }

  public get buildingLables(): LayerGroup {
    return this.labelOverlayService.getGroupLables(this._buildingLables);
  }

  public get tunnelLable(): LayerGroup {
    return this.labelOverlayService.getGroupLables(this._tunnelLable);
  }

  public get citylLable(): LayerGroup {
    return this.labelOverlayService.getGroupLables(this._citylLable);
  }
}
