import { Component, ComponentFactoryResolver, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { faCog, faUndo, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import { sidebarMenuItems, SidebarMenuItem } from '@data/sidebar.menu'
import { listAnimation } from '@settings/animations/list.animation';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [listAnimation],
})
export class SidebarComponent {
  public faCog = faCog
  public faUndo = faUndo
  public faCheck = faCheck
  public faChevronLeft = faChevronLeft
  public readonly sidebarMenuItems: Array<SidebarMenuItem> = sidebarMenuItems

  constructor(){
    this.resetMap();
  }

  @Output()
  private onLayerBtnClicked = new EventEmitter<SidebarMenuItem>()

  @Output()
  private onMapResetBtnClicked = new EventEmitter()

  @Output()
  private onSidebarCloseClicked = new EventEmitter()

  public toggleLayer(sidebarMenuItem: SidebarMenuItem): void {
    sidebarMenuItem.added = !sidebarMenuItem.added
    this.onLayerBtnClicked.emit(sidebarMenuItem)
  }

  public readonly resetMap = (): void => {
    sidebarMenuItems.forEach(sidebarMenuLable => sidebarMenuLable.added = false)
    this.onMapResetBtnClicked.emit()
  }

  public readonly closeSidebar = () => {
    this.onSidebarCloseClicked.emit()
  }

}
