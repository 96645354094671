import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "@models/user";

import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, tap, map, take, publishReplay, refCount } from "rxjs/operators";
import { Roles } from "@app/data/roles";

@Injectable({ providedIn: "root" })
export class AuthService {
  user$: Observable<User>;
  public isSigningIn: boolean;
  public selectedRole$ = new BehaviorSubject(Roles.Guest);
  public sharedSelectedRole$: Observable<Roles>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.sharedSelectedRole$ = this.selectedRole$.pipe(
      publishReplay(),
      refCount()
    );

    this.isSigningIn = true;

    // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        // Logged in
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          this.isSigningIn = false;
          return of(null);
        }
      })
    );
  }

  async signIn(value) {
    this.isSigningIn = true;

    await this.afAuth
      .signInWithEmailAndPassword(value.email, value.password)
      .then((response) => {
        this.updateUserData(response.user);
        this.router.navigate(["/home"]);
      })
      .catch((error) => {
        this.isSigningIn = false;
      });
  }

  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `users/${user.uid}`
    );

    const data = {
      uid: user.uid,
      email: user.email,
    };

    return userRef.set(data, { merge: true });
  }

  async signOut() {
    await this.afAuth.signOut();
    this.router.navigate(["/login"]);
  }
}
