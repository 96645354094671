import { Component, Input } from "@angular/core";
import { StatusSlider } from "@app/types/slider";
import { ColorClassName } from "./data/status-slider";

@Component({
  selector: "app-status-slider",
  templateUrl: "./status-slider.component.html",
  styleUrls: ["./status-slider.component.scss"],
})
export class StatusSliderComponent {
  @Input()
  slider: StatusSlider;

  @Input()
  title: string;

  @Input()
  secondeRangeColor: ColorClassName;

  @Input()
  thirdRangeColor: ColorClassName;

  public firstRange: string;
  public secondRange: string;
  public thirdRange: string;

  constructor() {}

  ngOnChanges(): void {
    if (!this.slider) {
      return;
    }
    if (this.slider.upperThreshold && this.slider.lowerThreshold) {
      this.calculateFilledValueWhenHaveUpperAndLowerThreshold();
    } else if (this.slider.upperThreshold) {
      this.calculateFilledValueWhenHaveUpperThreshold();
    } else {
      this.calculateFilledValueWhenOnlyHaveLowerVallue();
    }
  }

  private calculateFilledValueWhenHaveUpperAndLowerThreshold() {
    const { upperThreshold, lowerThreshold, lowerValue, ceil, floor } =
      this.slider;
    this.firstRange = this.getRangePercentage(lowerValue, floor, ceil, floor);
    this.secondRange = this.getRangePercentage(
      lowerThreshold,
      lowerValue,
      ceil,
      floor
    );
    this.thirdRange = this.getRangePercentage(
      upperThreshold,
      lowerThreshold,
      ceil,
      floor
    );
  }

  private calculateFilledValueWhenHaveUpperThreshold() {
    const { upperThreshold, lowerValue, ceil, floor } = this.slider;
    this.firstRange = this.getRangePercentage(lowerValue, floor, ceil, floor);
    this.secondRange = this.getRangePercentage(
      upperThreshold,
      lowerValue,
      ceil,
      floor
    );
    this.thirdRange = null;
  }

  private calculateFilledValueWhenOnlyHaveLowerVallue() {
    const { lowerValue, ceil, floor } = this.slider;
    this.firstRange = this.getRangePercentage(lowerValue, floor, ceil, floor);
    this.secondRange = null;
    this.thirdRange = null;
  }

  private getRangePercentage(
    upperValue: number,
    lowerValue: number,
    maxValue: number,
    minValue: number
  ): string {
    return `${((upperValue - lowerValue) / (maxValue - minValue)) * 100}%`;
  }
}
