import { Component, OnInit } from "@angular/core";
import { SelectableOptions } from "@app/data/option";
import { OptionsService } from "@app/shared/services/options.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
  selector: "app-options",
  templateUrl: "./options.component.html",
  styleUrls: ["./options.component.scss"],
})
export class OptionsComponent implements OnInit {
  public options: SelectableOptions[];
  public selectedOption: SelectableOptions;
  private ngUnsubscribe = new Subject();


  constructor(private optionsService: OptionsService) {
    this.options = Object.values(SelectableOptions);
  }

  ngOnInit(): void {
    this.onSubscribeOptionChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public selectOption(option: SelectableOptions) {
    this.optionsService.selectedOption$.next(option);
    this.selectedOption = option;
  }

  private onSubscribeOptionChanges() {
    this.optionsService.sharedSelectedOption$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((option) => (this.selectedOption = option));
  }
}
