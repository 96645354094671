<div *ngIf="slider">
  <p class="title">{{ title }}</p>
  <div class="slider-container">
    <div class="value-label">
      <div *ngIf="slider.upperThreshold" class="upperThreshold">
        <p [ngStyle]="{'top': slider.upperThresholdLabelStyle.pTop , 'left': slider.upperThresholdLabelStyle.pLeft}">
          {{ slider.upperThreshold }}
        </p>
        <hr [style.top]="slider.upperThresholdLabelStyle.hrTop" />
      </div>
      <div *ngIf="slider.lowerThreshold" class="lowerThreshold">
        <p [ngStyle]="{'top': slider.lowerThresholdLabelStyle.pTop , 'left': slider.lowerThresholdLabelStyle.pLeft}">
          {{ slider.lowerThreshold }}
        </p>
        <hr [style.top]="slider.lowerThresholdLabelStyle.hrTop" />
      </div>

      <div class="lowerValue">
        <p [ngStyle]="{'top': slider.lowerValueLabelStyle.pTop , 'left': slider.lowerValueLabelStyle.pLeft}">{{ slider.lowerValue }}</p>
        <hr [style.top]="slider.lowerValueLabelStyle.hrTop" />
      </div>
    </div>
    <div class="start-end-label">
      <p>{{ slider.ceil }}</p>
      <p>{{ slider.floor }}</p>
    </div>

    <div class="progress">
      <div
        class="progress-bar outer-range"
        role="progressbar"
        [style.width]="firstRange"
      ></div>
      <div
        class="progress-bar"
        [ngClass]="thirdRange ? secondeRangeColor : thirdRangeColor"
        role="progressbar"
        [style.width]="secondRange"
      ></div>
      <div
        *ngIf="thirdRange"
        [ngClass]="thirdRange ?  thirdRangeColor : null"
        class="progress-bar orange-range"
        role="progressbar"
        [style.width]="thirdRange"
      ></div>
    </div>
  </div>
</div>
