export abstract class Constants {
    static readonly OVERLAYS: string = 'overlays';
    static readonly BASE_LAYERS: string = 'baseLayers';
    static readonly ENVIRONMENTAL: string = 'Environmental';
    static readonly ARCHAEOLOGICAL: string = 'Archaeological';
    static readonly RECREATIONAL: string = 'Recreational';
    static readonly OPTION_1: string = 'Option 1';
    static readonly OPTION_2: string = 'Option 2';
    static readonly OPTION_3: string = 'Option 3';
    static readonly RESERVOIR: string = 'Reservoir';
    static readonly RIVER: string = 'River';
    static readonly LITTORAL_ZONE: string = 'Littoral Zone';

    static readonly COLORS: any = {
      'risk-level-1': '#89ec82',
      'risk-level-2': '#66b661',
      'risk-level-3': '#FFC633',
      'risk-level-4': '#ff8c01',
      'risk-level-5': '#f66a6a',
      'lake': '#5f8db1'
    }
  }