<ng-sidebar-container>
  <ng-sidebar
    #sidebar
    [(opened)]="opened"
    mode="over"
    dock="true"
    autoCollapseWidth="500"
  >
    <div class="sidebar">
      <perfect-scrollbar>
        <app-header></app-header>
        <div class="sliders">
          <app-status-slider
            class="outflow-slider"
            [slider]="outflowSlider"
            [title]="'Outflow (m3/s)'"
            [thirdRangeColor]="outflowSliderColor"
          >
          </app-status-slider>
          <app-level-slider class="level-slider" [title]="'Level (m)'">
          </app-level-slider>
        </div>
        <app-footer></app-footer>
      </perfect-scrollbar>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <app-page-name [name]="pageTitle"></app-page-name>
    <perfect-scrollbar>
      <div class="main-content">
        <div>
          <div
            class="map"
            leaflet
            [leafletOptions]="options"
            (leafletMapReady)="onMapLoaded($event)"
            [leafletLayers]="markers"
          ></div>
          <div class="bottom-bar">
            <app-options></app-options>
            <button
              (click)="reset()"
              type="button"
              class="button button-large reset"
            >
              <fa-icon [icon]="faUndo" size="sm"></fa-icon>
            </button>
          </div>
        </div>
        <div class="right-sidebar">
          <app-areas></app-areas>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</ng-sidebar-container>
