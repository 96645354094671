<div class="container-fluid">
  <app-header></app-header>
  <app-page-name [name]="pageTitle"></app-page-name>
  <div class="row canvas-row">
    <div class="col-md-12">
      <canvas #rendererCanvas></canvas>
    </div>
  </div>

  <div class="row d-flex justify-content-between button-groups">
    <div>
      <h5>Headworks</h5>
      <div class="button-group">
        <button
          *ngFor="let item of headworksItems"
          class="button button-large"
          [class.active]="item.selected"
          type="button"
          (click)="toggleItem(item)"
        >
          {{ item.title }}
        </button>
      </div>
    </div>
    <div>
      <h5>Surge</h5>
      <button
        *ngFor="let item of surgeItems"
        class="button button-large"
        [class.active]="item.selected"
        type="button"
        (click)="toggleItem(item)"
      >
        {{ item.title }}
      </button>
    </div>
    <div>
      <button
        (click)="resetModelScene()"
        type="button"
        class="button button-large reset"
      >
        <fa-icon [icon]="faUndo" size="sm"></fa-icon>
      </button>
    </div>
  </div>
  <app-footer></app-footer>
</div>
