export type LableType = {
  coordinates: [number, number]
  type?: string
  lableName: string
  className: string
}

export type LablesType = Array<LableType>

export class Lable implements LableType {

  constructor(
    public type: string,
    public coordinates: [number, number],
    public lableName: string,
    public className: string) { }
}

export class Lables {
  private readonly _Rivers: LablesType = [
    new Lable('river', [-78.75812842425712, 87.16999135543432], 'fraser', 'riverClassName'),
    new Lable('river', [-25.411292953996387, 39.829211182517014], 'pitt', 'riverClassName'),
    new Lable('river', [-48.31153904698554, 49.50074616198826], 'alouette south river', 'riverClassName'),
    new Lable('river', [-62.28472264826751, 71.42675234485093], 'stave', 'riverClassName'),
  ]

  private readonly _Buildings: LablesType = [
    new Lable('dam', [-39.4668111291528, 63.10230253983188], 'alouette', 'damClassName'),
    new Lable('power facility', [-27.132381415267634, 101.84951686278276], 'alouette', 'damClassName'),
    new Lable('dam & power facility', [-54.77785273481283, 84.58235605456854], 'stave falls', 'damClassName'),
    new Lable('dam & power facility', [-60.72272824644158, 72.45307565966036], 'ruskin', 'damClassName')
  ]

  private readonly _Lakes: LablesType = [
    new Lable('lake', [-59.53468119482372, 77.59576425392754], 'hayward', 'lakeClassName'),
    new Lable('lake', [-65.81045704979809, 70.18132700423331], 'silvermere', 'lakeClassName'),
    new Lable('lake', [-56.01494584388673, 65.29961494951726], 'whonnock', 'lakeClassName'),
    new Lable('lake', [-14.865088937534011, 54.703527290125166], 'pitt', 'lakeClassName'),
    new Lable('lake', [-34.142267191248635, 72.73787358135579], 'alouette', 'lakeClassName'),
    new Lable('lake', [-33.23510550259227, 42.059314655886], 'loon', 'lakeClassName'),
    new Lable('lake', [-28.912597983503655, 104.12994800742575], 'stave', 'lakeClassName'),
    new Lable('lake', [-9.365006030646427, 9.216355141271087], 'coquitlam', 'lakeClassName')
  ]

  private readonly _Tunnel: LablesType = [
    new Lable('tunnel', [-26.373899518567477,99.41314865788951], 'alouette', 'tunnelClassName'),
  ]

  private readonly _Districts: LablesType = [
    new Lable('district', [-85.99453950363426,87.77835017613835], 'abbotsford', 'cityClassName'),
    new Lable('district', [-73.06191212797967,13.081278265029932], 'langley', 'cityClassName'),
    new Lable('district', [-48.53451538104855,31.216686768821425], 'maple ridge', 'cityClassName'),
    new Lable('district', [-79.50035162406687,154.46489480485192], 'chilliwack', 'cityClassName')
  ]

  get Rivers(): Array<LableType> {
    return this._Rivers
  }

  get Buildings(): Array<LableType> {
    return this._Buildings
  }

  get Lakes(): Array<LableType> {
    return this._Lakes
  }

  get Tunnel(): Array<LableType> {
    return this._Tunnel
  }

  get Districts(): Array<LableType> {
    return this._Districts
  }


}


