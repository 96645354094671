<img src="../../assets/logos/bchydro-logo-light.png" alt="Bc Hydro logo">

<form [formGroup]="authForm" (ngSubmit)="signIn()">
  <div class="form-body">
    <legend>Alouette Hydro System</legend>
    <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
      <label for="email">email</label>
      <input id="email" class="form-control" type="email" placeholder="Email" formControlName="email">
    </div>
    <div *ngIf="isSubmitted && formControls.email.errors">
      <small class="text-danger" *ngIf="formControls.email.errors.required">Email is required</small>
    </div>
    <div class="form-group" [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
      <label for="password">password</label>
      <input id="password" class="form-control" type="password" placeholder="Password" formControlName="password">
    </div>
    <div *ngIf="isSubmitted && formControls.password.errors">
      <small class="text-danger" *ngIf="formControls.password.errors.required">Password is required</small>
    </div>
  </div>

  <div class="form-footer">
    <input type="submit" value="enter" class="btn btn-success">
    <button class="btn btn-light" (click)="authForm.reset()">Cancel</button>
  </div>


</form>

<img src="../../assets/logos/truescape-logo-light.png" alt="">
