import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from './app.component'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { AreasOfInfluenceComponent } from './pages/areas-of-influence/areas-of-influence.component'
import { PowerPlantSystemModelComponent } from './pages/power-plant-system-model/power-plant-system-model.component'
import { SystemOverviewComponent } from './pages/system-overview/system-overview.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SettingsModule } from './settings/settings.module'
import { NgxLocalStorageModule } from 'ngx-localstorage'
import { HomepageComponent } from './pages/homepage/homepage.component'
import { LoginComponent } from './pages/login/login.component'
import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { environment } from '../environments/environment'
import { AuthGuard } from '@services/auth.guard'
import { ReactiveFormsModule } from '@angular/forms'
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { SidebarComponent } from './pages/sidebar/sidebar.component'
import { SidebarModule } from 'ng-sidebar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { CommonModule } from "@angular/common";
import { SystemRecallComponent } from './pages/system-recall/system-recall.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ComponentsModule } from './shared/components/components.module';
import { PageNameComponent } from './shared/components/page-name/page-name.component';
import { AppHeaderComponent } from './shared/components/app-header/app-header.component';
import { OptionsComponent } from './shared/components/options/options.component';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'

@NgModule({
  declarations: [
    AppComponent,
    SystemOverviewComponent,
    AreasOfInfluenceComponent,
    PowerPlantSystemModelComponent,
    HomepageComponent,
    LoginComponent,
    SidebarComponent,
    SystemRecallComponent,
    PageNameComponent,
    AppHeaderComponent,
    OptionsComponent,
  ],
  exports: [
    SidebarComponent,
    ComponentsModule
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserModule,
    CommonModule,
    LeafletModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxLocalStorageModule.forRoot(),
    SettingsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    FontAwesomeModule,
    NgxSliderModule,
    ComponentsModule,
    ToastrModule.forRoot(),
    PerfectScrollbarModule,
  ],
  providers: [AuthGuard, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
