import { Component, ElementRef } from "@angular/core";
import { Options } from "@angular-slider/ngx-slider";
import * as L from "leaflet";
import { StatusSlider, SystemRecallInflowSlider } from "@app/types/slider";
import { InflowPeriod } from "@app/data/inflow-period";
import { SystemRecallMapService } from "@app/pages/system-recall/services/system-recall-map.service";
import { BehaviorSubject, Subject } from "rxjs";
import { SystemRecallLayerService } from "./services/system-recall-layers.service";
import { Layer } from "leaflet";
import { OptionsService } from "@app/shared/services/options.service";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { AdjustableSliderService } from "@app/shared/components/adjustable-slider/adjustable-slider.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-system-recall",
  templateUrl: "./system-recall.component.html",
  styleUrls: ["./system-recall.component.scss"],
})
export class SystemRecallComponent {
  public pageTitle = "System Recall";
  public opened: boolean = true;
  public map: L.Map;
  public options: L.MapOptions;
  public flowOptions: Options;

  public inflowPeriodTableDate: string[] = [];
  public levelSlider: StatusSlider;

  public selectedPeriod$: BehaviorSubject<InflowPeriod>;
  public layersControl: Object;

  public inflowSlider$: BehaviorSubject<SystemRecallInflowSlider>;

  public faUndo = faUndo;

  private ngUnsubscribe = new Subject();

  constructor(
    private systemRecallService: SystemRecallMapService,
    private systemRecallLayerService: SystemRecallLayerService,
    private elementRef: ElementRef,
    private optionsService: OptionsService,
    private adjustableSliderService: AdjustableSliderService,
    private toastr: ToastrService
  ) {
    this.inflowPeriodTableDate = Object.values(InflowPeriod);
  }

  public ngOnInit() {
    this.systemRecallService.onSubscribeOptionAndPeriodChanges(
      this.ngUnsubscribe
    );
    this.inflowSlider$ = this.systemRecallService.inflowSlider$;
    this.options = this.systemRecallService.options;
    this.layersControl = {
      baseLayers: this.systemRecallService.baseLayers,
      overlays: this.systemRecallService.overlays,
    };
    this.selectedPeriod$ = this.systemRecallService.selectedPeriod$;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.reset();
  }

  public onMapLoaded(map: L.Map): void {
    this.map = map;
    const southWest = map.unproject([0, 5632], map.getMaxZoom());
    const northEast = map.unproject([10240, 0], map.getMaxZoom());
    map.setMaxBounds(new L.LatLngBounds(southWest, northEast));
    map.on("click", <LeafletMouseEvent>(e) => {
      console.log(`[${e.latlng.lat},${e.latlng.lng}]`);
    });
    this.addLakeOverlay();
    this.addTunnelOverlays();
    this.addLakeInflowOverlay();
    this.animate();
  }

  private addLakeOverlay() {
    const lakeOverlay = this.systemRecallLayerService.lakeOverlay;
    this.addLayer(lakeOverlay);
  }

  private addTunnelOverlays() {
    const tunnelOverlay = this.systemRecallLayerService.tunnelOverlays;
    const normalOverlays =
      this.systemRecallLayerService.normalTunnelOuterOverlays;
    this.addLayer(tunnelOverlay);
    this.addLayer(normalOverlays);
  }
  private addLakeInflowOverlay() {
    const lakeInflowOverlay = this.systemRecallLayerService.lakeInflowOverlay;
    this.addLayer(lakeInflowOverlay);
  }

  public onPeriodButtonsClick(period: InflowPeriod) {
    this.systemRecallService.selectedPeriod$.next(period);
  }

  public onOverUpperThresholdStatusChanges(isOver: boolean) {
    const normalOverlays =
      this.systemRecallLayerService.normalTunnelOuterOverlays;
    const riskyOverlays =
      this.systemRecallLayerService.riskyTunnelOuterOverlays;

    if (isOver) {
      this.addTunnelOutFlowOverlay();
      this.swapOverlay(normalOverlays, riskyOverlays);
      this.toastr.warning("SYSTEM IS IN RECALL");
    } else {
      this.removeFlowOverlays();
      this.swapOverlay(riskyOverlays, normalOverlays);
    }
  }

  private swapOverlay(
    currentOverlays: L.LayerGroup,
    newOverlays: L.LayerGroup
  ) {
    if (this.map?.hasLayer(currentOverlays)) {
      this.map.removeLayer(currentOverlays);
      this.map.addLayer(newOverlays);
    }
  }

  private addTunnelOutFlowOverlay() {
    const tunnelOutflowOverlay =
      this.systemRecallLayerService.tunnelOutflowOverlay;
    this.addLayer(tunnelOutflowOverlay);
  }

  private addLayer(layer: Layer) {
    if (this.map.hasLayer(layer)) {
      return;
    }
    this.map.addLayer(layer);
  }

  animate() {
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements: NodeListOf<HTMLVideoElement> =
      dom.querySelectorAll(".webm");
    elements.forEach((element) => {
      element.play();
    });
  }

  private removeFlowOverlays() {
    const tunnelOutflowOverlay =
      this.systemRecallLayerService.tunnelOutflowOverlay;

    if (this.map?.hasLayer(tunnelOutflowOverlay)) {
      this.map.removeLayer(tunnelOutflowOverlay);
    }
  }

  public reset() {
    this.systemRecallService.selectedPeriod$.next(InflowPeriod.OneDay);
    this.optionsService.resetDefaultSelectedOption();
    this.adjustableSliderService.slideIsReset$.next(true);
  }
}
