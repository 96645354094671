import { Component, OnInit } from "@angular/core";
import { SelectableAreaCategories } from "@app/data/area-categories";
import { AreasService } from "@app/shared/services/areas.service";
import { areas } from "@data/areas.json";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-area-categories",
  templateUrl: "./area-categories.component.html",
  styleUrls: ["./area-categories.component.scss"],
})
export class AreaCategoriesComponent implements OnInit {
  public areaCategory: SelectableAreaCategories;
  public areaCategories: SelectableAreaCategories[];
  public selectedAreaCategory: SelectableAreaCategories;
  public influenceAreas: Object;

  private ngUnsubscribe = new Subject();

  constructor(private areasService: AreasService) {
    this.areaCategories = Object.values(SelectableAreaCategories);
  }

  ngOnInit(): void {
    this.onSubscribeAreaCategoryChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onSubscribeAreaCategoryChanges() {
    this.areasService.sharedSelectedAreaCategory$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((areaCategory) => {
        this.selectedAreaCategory = areaCategory;
        this.influenceAreas = areas[areaCategory];
      });
  }

  selectAreaCategory(areaCategory: SelectableAreaCategories) {
    this.areasService.selectedAreaCategory$.next(areaCategory);
    this.selectedAreaCategory = areaCategory;
  }
}
