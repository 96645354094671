export const ModelInternalData = {
  headworks: {
    items: [
      {
        slug: "headworks_tower",
        title: "Tower Structure",
        modelName: "HeadWorks_Tower",
        sprite: "headworks_tower_slug",
        selected: false,
        positionY: 16,
        positionX: 0,
        animateThis: 1,
        cameraTweenTo: {
          x: -3.8344273494934655,
          y: 43.62513810386102,
          z: 20.144520791013992,
        },
        controlTweenTo: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      {
        slug: "headworks_gate",
        title: "Gate",
        modelName: "HeadWorks_Gate",
        sprite: "headworks_gate",
        selected: false,
        positionY: 0,
        positionX: -2,
        animateThis: 1,
        cameraTweenTo: {
          x: 7.100361532039375,
          y: -15.003840673486557,
          z: 8.861818216399069,
        },
        controlTweenTo: {
          x: -4.150368726195814,
          y: -12.390627092591362,
          z: 2.1249100820399476,
        },
      },
      {
        slug: "headworks_shaft",
        title: "Shaft",
        modelName: "HeadWorks_Tower",
        sprite: "headworks_shaft_slug",
        selected: false,
        positionY: 1.5,
        positionX: -2,
        positionZ: 2.5,
        animateThis: 1,
        cameraTweenTo: {
          x: 0.6575070234397903,
          y: 8.076026881404514,
          z: 47.51844589108577,
        },
        controlTweenTo: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      {
        slug: "headworks_slopes",
        title: "Slopes",
        modelName: "HeadWorks_Slope",
        sprite: "headworks_slopes_slug",
        selected: false,
        positionY: 5.5,
        positionX: 0,
        animateThis: 1,
        cameraTweenTo: {
          x: -16.08686160049306,
          y: 26.62680844337074,
          z: 4.564462484458369,
        },
        controlTweenTo: {
          x: -11.358577344000917,
          y: 0.33251325826246986,
          z: -1.6027433823480488,
        },
      },
      {
        slug: "headworks_stoplogs",
        title: "Stoplogs",
        modelName: "HeadWorks_Screen",
        sprite: "headworks_stoplogs_slug",
        selected: false,
        positionY: 2,
        positionX: 0,
        positionZ: 3,
        animateThis: 1,
        cameraTweenTo: {
          x: -38.83979972573059,
          y: -12.065759611935508,
          z: 7.9422019876389385,
        },
        controlTweenTo: {
          x: -6.617356883722235,
          y: -18.02905297113941,
          z: -9.873878520933879,
        },
      },
      {
        slug: "headworks_construction_adit",
        title: "Construction Adit",
        modelName: "HeadWorks_Block",
        sprite: "headworks_construction_adit_slug",
        selected: false,
        positionY: 0,
        positionX: 2,
        positionZ: 1,
        animateThis: 1,
        cameraTweenTo: {
          x: -3.1285928685570568,
          y: -14.245459498498661,
          z: 14.829875912228683,
        },
        controlTweenTo: {
          x: -12.315116831213885,
          y: -10.823031020838817,
          z: 5.7365537155520405,
        },
      },
    ],
  },
  surge: {
    items: [
      {
        slug: "surge_structure",
        title: "Tower Structure",
        modelName: "SurgeTower_Structure",
        sprite: "surge_tower_slug",
        selected: false,
        positionY: 20,
        positionX: -6,
        animateThis: 1,
        cameraTweenTo: {
          x: 37.98938750969847,
          y: 30.579316587395994,
          z: 14.294193335285682,
        },
        controlTweenTo: {
          x: 15.552798840300577,
          y: -4.432675028111243,
          z: -10.086478467906646,
        },
      },
      {
        slug: "surge_shaft",
        title: "Surge Shaft",
        modelName: "SurgeTower_Structure",
        sprite: "surge_shaft_slug",
        selected: false,
        positionY: 5,
        positionX: -8,
        positionZ: 2.5,
        animateThis: 1,
        cameraTweenTo: {
          x: 18.235719307101135,
          y: 5.413589882719095,
          z: 46.03377649129711,
        },
        controlTweenTo: {
          x: 5.122196190411674,
          y: -0.8113500289966468,
          z: 0.06701828340666444,
        },
      },
      {
        slug: "surge_slopes",
        title: "Slopes",
        modelName: "SurgeTower_Slope",
        sprite: "surge_slopes_slug",
        selected: false,
        positionY: 2.5,
        positionX: 0,
        animateThis: 1,
        cameraTweenTo: {
          x: 17.790323811134986,
          y: 43.86362930145892,
          z: 13.002318434060498,
        },
        controlTweenTo: {
          x: 5.122196190411674,
          y: -0.8113500289966468,
          z: 0.06701828340666444,
        },
      },
    ],
  },
  outer: {
    items: [],
  },
};

export const initialCameraTweenTo = {
  x: 0.6575070234397902,
  y: 8.076026881404516,
  z: 47.518445891085776,
};
