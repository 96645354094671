import { Injectable } from "@angular/core";
import { SelectableAreaCategories } from "@app/data/area-categories";
import { BehaviorSubject, Observable } from "rxjs";
import { publishReplay, refCount } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AreasService {
  public selectedAreaCategory$ = new BehaviorSubject(
    SelectableAreaCategories.Environmental
  );
  public sharedSelectedAreaCategory$: Observable<SelectableAreaCategories>;

  constructor() {
    this.sharedSelectedAreaCategory$ = this.selectedAreaCategory$.pipe(
      publishReplay(),
      refCount()
    );
  }

  public resetDefaultAreaCategory() {
    this.selectedAreaCategory$.next(SelectableAreaCategories.Environmental);
  }
}
