import { Constants } from '@constants';

export interface ISidebarMenuItem {
  text: string,
  control: string,
  added: boolean
}

export class SidebarMenuItem implements ISidebarMenuItem {
  constructor(public text: string, public control: string, public added: boolean = false) { }
}

export const sidebarMenuItems: Array<SidebarMenuItem> = [
  new SidebarMenuItem('lakes & reservoirs', Constants.OVERLAYS),
  new SidebarMenuItem('power facilities & dams', Constants.OVERLAYS),
  new SidebarMenuItem('tunnel', Constants.OVERLAYS),
  new SidebarMenuItem('rivers', Constants.OVERLAYS),
  new SidebarMenuItem('creeks', Constants.OVERLAYS),
  new SidebarMenuItem('rain inflows', Constants.OVERLAYS),
  new SidebarMenuItem('snow inflows', Constants.OVERLAYS),
  new SidebarMenuItem('snow', Constants.BASE_LAYERS),
  new SidebarMenuItem('districts', Constants.OVERLAYS),
]
