<div *ngIf="slider">
  <p class="title">{{ title }}</p>
  <div class="slider-container">
    <div class="start-end-label">
      <p>{{ slider.ceil }}</p>
      <p>{{ slider.floor }}</p>
    </div>

    <div *ngIf="slider.upperThreshold !== slider.ceil" class="threshold-label">
      <p [style]="thresholdLableTextStyle">{{ slider.upperThreshold }}</p>
      <hr [style]="thresholdLableHyphenStyle" />
    </div>

    <div *ngIf="slider">
      <input
        class="input-range"
        type="range"
        [min]="slider.floor"
        [max]="slider.ceil"
        [(ngModel)]="sliderValue"
        [style]="sliderStyle"
        (change)="onSliderValueChange()"
      />
    </div>
  </div>
</div>
