import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FlowsService {
  constructor() {}

  getFlows(
    color: string,
    paths: Array<string>,
    scales: number[],
    points: string,
    speed: string,
    begins: string[]
  ) {
    const inflowElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    inflowElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    inflowElement.setAttribute("viewBox", "0 0 10240 5632");
    paths.forEach((path) => {
      inflowElement.innerHTML += `
      <polygon transform="scale(${scales[0]})" fill="${color}" points="${points}">
          <animateMotion begin="${begins[0]}" rotate="auto" dur="${speed}" repeatCount="indefinite" path="${path}"></animateMotion>
      </polygon>
      <polygon transform="scale(${scales[1]})" fill="${color}" points="${points}">
         <animateMotion begin="${begins[1]}" rotate="auto" dur="${speed}" repeatCount="indefinite" path="${path}"></animateMotion>
      </polygon>
      <polygon transform="scale(${scales[2]})" fill="${color}" points="${points}">
        <animateMotion begin="${begins[2]}" rotate="auto" dur="${speed}" repeatCount="indefinite" path="${path}"></animateMotion>
      </polygon>
      `;
    });
    return inflowElement;
  }
}
