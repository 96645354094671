import { Component, ElementRef } from "@angular/core";
import * as L from "leaflet";
import { AreasOfInfluenceMapService } from "./services/areas-of-influence-map.service";
import { OptionsService } from "@app/shared/services/options.service";
import { takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { StatusSlider } from "@app/types/slider";
import { outflowSliders } from "@data/area-of-influence.json";
import { ColorClassName } from "@app/shared/components/status-slider/data/status-slider";
import { AreasService } from "@app/shared/services/areas.service";
import { Constants } from "@app/constants";
import { SelectableOptions } from "@app/data/option";
import { SelectableAreaCategories } from "@app/data/area-categories";
import { Marker } from "leaflet";
import { AreasOfInfluenceLayersService } from "./services/areas-of-influence-layers.service";
import { AuthService } from "@app/services/auth.service";
import { AreasOfInfluenceService } from "./services/areas-of-influence.service";
import { Roles } from "@app/data/roles";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-areas-of-influence",
  templateUrl: "./areas-of-influence.component.html",
  styleUrls: [
    "./areas-of-influence.component.scss",
    "../../../markers.scss",
    "./markers.scss",
  ],
})
export class AreasOfInfluenceComponent {
  public pageTitle = "Areas Of Influence";
  public map: L.Map;
  public options: L.MapOptions;
  public opened: boolean = true;
  public layersControl: Object;
  public outflowSlider: StatusSlider;

  public outflowSliderColor = ColorClassName.Green;

  public markers: Marker[] = [];
  public currentLabels = {};

  public faUndo = faUndo;

  private ngUnsubscribe = new Subject();

  private overlayMap = {
    reservoir: `${Constants.RESERVOIR} ${Constants.OPTION_1} ${Constants.ENVIRONMENTAL}`,
    river: `${Constants.RIVER} ${Constants.OPTION_1} ${Constants.ENVIRONMENTAL}`,
    littoralZone: `${Constants.LITTORAL_ZONE} ${Constants.OPTION_1} ${Constants.ENVIRONMENTAL}`,
    archaeologicalZone: null,
  };

  private readonly RESERVIOUR = "reservoir";
  private readonly RIVER = "river";
  private readonly LITTORAL_ZONE = "littoralZone";
  private readonly ARCHAEOLOGICAL_ZONE = "archaeologicalZone";

  constructor(
    private mapService: AreasOfInfluenceMapService,
    private optionsService: OptionsService,
    private areasService: AreasService,
    private layersService: AreasOfInfluenceLayersService,
    private authService: AuthService,
    private elementRef: ElementRef,
    private areasOfInfluenceService: AreasOfInfluenceService
  ) {}

  public ngOnInit() {
    this.options = this.mapService.options;
    this.layersControl = {
      baseLayers: this.mapService.baseLayers,
      overlays: this.mapService.overlays,
    };
    this.outflowSlider = outflowSliders[Constants.OPTION_1];
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.reset();
  }

  switchLayer(areaName, layerName) {
    if (!this.map) {
      return;
    }
    const currentLayer =
      this.layersControl["overlays"][this.overlayMap[areaName]];
    if (currentLayer) {
      this.removeLayer(currentLayer);
    }
    this.displayLayer(layerName);
    this.overlayMap[areaName] = layerName;
  }

  displayLayer(layerName) {
    const newLayer = this.layersControl["overlays"][layerName];
    if (newLayer) {
      this.map.addLayer(newLayer);
    }
  }

  removeLayer(layer) {
    if (this.map.hasLayer(layer)) {
      this.map.removeLayer(layer);
    }
  }

  displayWaves() {
    const wavesLayer: L.LayerGroup = this.layersControl["overlays"]["wave"];
    if (wavesLayer) {
      this.map.addLayer(wavesLayer);
      wavesLayer.setZIndex(500);
    }
  }

  private removeLittoralZone(selectedOption: SelectableOptions) {
    const littoralLayer =
      this.layersControl["overlays"][
        `${Constants.LITTORAL_ZONE} ${selectedOption} ${Constants.ENVIRONMENTAL}`
      ];
    if (littoralLayer) {
      this.removeLayer(littoralLayer);
    }
  }

  public onSubscribeChanges() {
    combineLatest(
      this.areasService.sharedSelectedAreaCategory$,
      this.optionsService.sharedSelectedOption$,
      this.authService.sharedSelectedRole$
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([areaCategory, selectedOption, selectedRole]) => {
        if (this.map) {
          this.outflowSlider = outflowSliders[selectedOption];
          this.displayLabels(areaCategory, selectedOption);
          this.flashDamOverlay(areaCategory, selectedOption);
          this.displayContentByRole(areaCategory, selectedOption, selectedRole);
          this.displayLayers(areaCategory, selectedOption, selectedRole);
        }
      });
  }

  displayLayers(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions,
    role: Roles
  ) {
    if (this.map) {
      this.switchLayer(
        this.RESERVIOUR,
        `${Constants.RESERVOIR} ${selectedOption} ${areaName}`
      );
      this.switchLayer(this.RIVER, `River ${selectedOption} ${areaName}`);
      if (role === Roles.Admin) {
        this.switchLayer(
          this.LITTORAL_ZONE,
          `${Constants.LITTORAL_ZONE} ${selectedOption} ${areaName}`
        );
      } else {
        this.removeLittoralZone(selectedOption);
      }
    }
  }

  displayContentByRole(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions,
    role: Roles
  ) {
    let archaeologicalLayerName = `${Constants.ARCHAEOLOGICAL} ${selectedOption}`;
    if (areaName === Constants.ARCHAEOLOGICAL) {
      this.switchLayer(this.ARCHAEOLOGICAL_ZONE, archaeologicalLayerName);
    } else {
      const archaeologicalLayer =
        this.layersControl["overlays"][
          this.overlayMap[this.ARCHAEOLOGICAL_ZONE]
        ];
      if (archaeologicalLayer) {
        this.removeLayer(archaeologicalLayer);
      }
    }
    if (role === Roles.Admin) {
      this.displayLayer(
        `${Constants.LITTORAL_ZONE} ${selectedOption} ${areaName}`
      );
      this.displayMarkers(areaName, selectedOption);
    } else {
      this.removeLittoralZone(selectedOption);
      if (areaName === Constants.ARCHAEOLOGICAL) {
        this.markers = [];
      }
    }
  }

  private displayMarkers(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions
  ) {
    this.markers = this.layersService.getMarkers(areaName, selectedOption);
  }

  private displayLabels(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions
  ) {
    this.removeLayer(this.currentLabels);
    const labels = this.layersService.getLabelsByAreaNameAndOption(
      areaName,
      selectedOption
    );

    if (labels) {
      this.map.addLayer(labels);
    }
    this.currentLabels = labels;
  }

  private flashDamOverlay(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions
  ) {
    const riskLevel = this.areasOfInfluenceService.getRiverRiskLevel(
      areaName,
      selectedOption,
      Constants.RIVER
    );
    const element = this.elementRef.nativeElement.querySelector(".dam");

    if (riskLevel > 3) {
      const color = Constants.COLORS[`risk-level-${riskLevel}`];
      element.style.filter = `opacity(0.5) drop-shadow(0 0 0 ${color})`;
      element.classList.add("pulse");
    } else {
      element.style.filter = "none";
      element.classList.remove("pulse");
    }
  }

  public onMapLoaded(map: L.Map): void {
    this.map = map;
    const southWest = map.unproject([0, 5632], map.getMaxZoom());
    const northEast = map.unproject([10240, 0], map.getMaxZoom());
    map.setMaxBounds(new L.LatLngBounds(southWest, northEast));
    map.on("click", <LeafletMouseEvent>(e) => {
      console.log(`[${e.latlng.lat},${e.latlng.lng}]`);
    });
    this.switchLayer(this.RESERVIOUR, this.overlayMap.reservoir);
    this.switchLayer(this.RIVER, this.overlayMap.river);
    this.switchLayer(this.LITTORAL_ZONE, this.overlayMap.littoralZone);
    this.displayLayer("Dam & Power Station");
    this.displayWaves();
    this.onSubscribeChanges();
  }

  public reset() {
    this.areasService.resetDefaultAreaCategory();
    this.optionsService.resetDefaultSelectedOption();
  }
}
