import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@services/auth.guard'
import { LoginComponent } from '@app/pages/login/login.component';
import { HomepageComponent } from '@app/pages/homepage/homepage.component';
import { SystemOverviewComponent } from '@app/pages/system-overview/system-overview.component'
import { AreasOfInfluenceComponent } from './pages/areas-of-influence/areas-of-influence.component';
import { PowerPlantSystemModelComponent } from './pages/power-plant-system-model/power-plant-system-model.component';
import { SystemRecallComponent } from './pages/system-recall/system-recall.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'app' }
  },
  {
    path: 'home',
    component: HomepageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'app' }
  },
  {
    path: 'map',
    component: SystemOverviewComponent,
    canActivate: [AuthGuard],
    data: { animation: 'app' }
  },
  {
    path: 'areas-of-influence',
    component: AreasOfInfluenceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'app' }
  },
  {
    path: 'system-recall',
    component: SystemRecallComponent,
    canActivate: [AuthGuard],
    data: { animation: 'app' }
  },
  {
    path: 'power-plant-system-model',
    component: PowerPlantSystemModelComponent,
    canActivate: [AuthGuard],
    data: { animation: 'app' }
  },
  {
    path: '**',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
