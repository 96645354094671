import { Component, Input, OnInit } from "@angular/core";
import { SelectableOptions } from "@app/data/option";
import { OptionsService } from "@app/shared/services/options.service";
import { StatusSlider } from "@app/types/slider";
import { options } from "@data/level-slider-options.json";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ColorClassName } from "../status-slider/data/status-slider";

@Component({
  selector: "app-level-slider",
  templateUrl: "./level-slider.component.html",
  styleUrls: ["./level-slider.component.scss"],
})
export class LevelSliderComponent {
  @Input()
  title: string;

  public slider: StatusSlider;
  public option: SelectableOptions;

  public secondeRangeColor = ColorClassName.Green;
  public thirdRangeColor = ColorClassName.Orange;

  private ngUnsubscribe = new Subject();

  constructor(private optionsService: OptionsService) {}

  ngOnInit(): void {
    this.onSubscribeOptionChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onSubscribeOptionChanges() {
    this.optionsService.sharedSelectedOption$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((option) => (this.slider = options[option]));
  }
}
