export const WaveFlowPoints =
  "0.5 73.9 7.3 60.3 9.5 43.5 8 27.6 7 18 4.6 8.7 0.5 0.5 4 5.2 6.6 11.2 8.3 17.5 13.1 36.2 11 59.2 0.5 73.9";

export const ArrowFlowPoints =
  "8.83 0 0 8.83 26.78 35.61 0 62.39 8.83 71.22 44.41 35.63 44.39 35.61 44.41 35.58 8.83 0";

export const SmallestFlowSize = [0.1, 0.1, 0.1];
export const SmallFlowSize = [0.5, 0.75, 1];
export const MediumFlowSize = [0.75, 1, 1.25];

export const NormalFlowSpeed = "1500ms"
export const SlowFlowSpeed = "3500ms"

export const NormalFlowAnimationBegins = ["0ms", "250ms", "500ms"]
export const LongerFlowAnimationBegins = ["0ms", "750ms", "1500ms"]

