<app-header></app-header>
<nav>
  <h2>System overview</h2>
  <ul [@listAnimation]="sidebarMenuItems.length">
    <li
      [ngClass]="{ added: sidebarMenuItem.added }"
      *ngFor="let sidebarMenuItem of sidebarMenuItems; let i = index"
      (click)="toggleLayer(sidebarMenuItem)"
    >
      {{ sidebarMenuItem.text }}
      <fa-icon
        class="float-right mr-2 text-success"
        [hidden]="!sidebarMenuItem.added"
        [icon]="faCheck"
        size="sm"
      ></fa-icon>
    </li>
  </ul>
</nav>
<nav>
  <fa-icon [icon]="faCog" size="sm" tooltip="Settings"></fa-icon>
  <fa-icon
    [icon]="faUndo"
    size="sm"
    (click)="resetMap()"
    tooltip="Reset Map"
  ></fa-icon>
  <fa-icon
    [icon]="faChevronLeft"
    size="sm"
    tooltip="Hide Sidemenu"
    (click)="closeSidebar()"
  ></fa-icon>
</nav>
<a class="ts-logo" href="https://truescape.com/" target="_blank">
  <img src="../../assets/logos/truescape-logo-light.png" alt="Truescape Logo" />
</a>
