<div class="header" routerLink="/home">
  <span class="back">
    <i class="fa fa-chevron-left" alt="Back"></i>
  </span>
  <img
    class="logo"
    routerLink="/home"
    src="../../../../assets/logos/bchydro-logo-light.png"
    alt="BC Hydro Logo"
  />
</div>
