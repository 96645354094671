import { Injectable } from "@angular/core";
import { Constants } from "@app/constants";
import {
  SmallFlowSize,
  ArrowFlowPoints,
  SlowFlowSpeed,
  LongerFlowAnimationBegins,
  NormalFlowSpeed,
  NormalFlowAnimationBegins,
} from "@app/data/flow-type";
import { FlowsService } from "@app/shared/services/flows-overlay.service";
import { OverlaysService } from "@app/shared/services/overlays.service";
import * as L from "leaflet";
import { LakeInflow } from "../data/lake-inflow";
import { TunnelOutflow } from "../data/tunnel-outflow";

@Injectable({
  providedIn: "root",
})
export class SystemRecallLayerService {
  public lakeOverlay: L.SVGOverlay;
  public lakeInflowOverlay: L.SVGOverlay;
  public tunnelOutflowOverlay: L.SVGOverlay;
  public tunnelOverlays: L.LayerGroup;
  public normalTunnelOuterOverlays: L.LayerGroup;
  public riskyTunnelOuterOverlays: L.LayerGroup;

  constructor(
    private flowsService: FlowsService,
    private overlaysService: OverlaysService
  ) {
    this.lakeOverlay = this.getLakeOverlay();
    this.lakeInflowOverlay = this.getLakeInflowOverlay();
    this.tunnelOutflowOverlay = this.getTunnelOutflowOverlay();
    this.tunnelOverlays = this.getTunnelOverlays();
    this.normalTunnelOuterOverlays = this.getNormalTunnelOuterOverlays();
    this.riskyTunnelOuterOverlays = this.getRiskyTunnelOuterOverlays();
  }

  private getTunnelOverlays(): L.LayerGroup {
    return L.layerGroup([this.sysRecallTunnel, this.sysRecallHLTunnel]);
  }

  private getNormalTunnelOuterOverlays(): L.LayerGroup {
    return L.layerGroup([
      this.normalOuterTunnelOverlay,
      this.normalOuterHLTunnelOverlay,
    ]);
  }

  private getRiskyTunnelOuterOverlays(): L.LayerGroup {
    return L.layerGroup([
      this.riskyOuterTunnelOverlay,
      this.riskyOuterHLTunnelOverlay,
    ]);
  }

  private get sysRecallTunnel(): L.ImageOverlay {
    return L.imageOverlay("../../assets/layers/upgrade-tunnel.svg", [
      [0, 0],
      this.overlaysService.bounds,
    ]);
  }

  private get normalOuterTunnelOverlay(): L.SVGOverlay {
    const svgEle = this.overlaysService.getBaseSVGEle();
    svgEle.innerHTML = this.getTunnelOuterSVGHtml(
      Constants.COLORS["risk-level-1"]
    );
    return L.svgOverlay(svgEle, this.overlaysService.bounds);
  }

  private get riskyOuterTunnelOverlay(): L.SVGOverlay {
    const svgEle = this.overlaysService.getBaseSVGEle();
    svgEle.innerHTML = this.getTunnelOuterSVGHtml(
      Constants.COLORS["risk-level-5"]
    );
    return L.svgOverlay(svgEle, this.overlaysService.bounds, {
      className: ".tunnelOuterOverlay",
    });
  }

  private get sysRecallHLTunnel(): L.ImageOverlay {
    return L.imageOverlay("../../assets/layers/system_recall_hl_tunnel.svg", [
      [0, 0],
      this.overlaysService.bounds,
    ]);
  }

  private get normalOuterHLTunnelOverlay(): L.SVGOverlay {
    const svgEle = this.overlaysService.getBaseSVGEle();
    svgEle.innerHTML = this.getHlTunnelOuterSVGHtml(
      Constants.COLORS["risk-level-1"]
    );
    return L.svgOverlay(svgEle, this.overlaysService.bounds);
  }

  private get riskyOuterHLTunnelOverlay(): L.SVGOverlay {
    const svgEle = this.overlaysService.getBaseSVGEle();
    svgEle.innerHTML = this.getHlTunnelOuterSVGHtml(
      Constants.COLORS["risk-level-5"]
    );
    return L.svgOverlay(svgEle, this.overlaysService.bounds, {
      className: "pulse",
    });
  }

  private getTunnelOuterSVGHtml(color) {
    return `
    <path d="M2155.4 4553.9C2151.5 4551.9 2147.2 4550.9 2142.7 4550.9C2135.3 4550.9 2127.9 4553.8 2122.6 4558.9C2116.8 4564.5 2113.8 4572.1 2114.4 4579.8C2114.5 4581.4 2114.8 4583 2115.2 4584.5C2152.9 4583.2 2169.1 4583 2197 4582.9L2158.8 4555.9C2157.7 4555.1 2156.6 4554.5 2155.4 4553.9Z" opacity="0.8" fill="${color}"/>
    <path d="M2345.8 4683.8L2346.1 4707.2C2344.4 4708.2 2342.9 4709.4 2341.5 4710.8C2336.9 4715.2 2334.1 4720.9 2333.5 4727L2324.1 4735.9L2378.3 4774.2L2391.8 4783.6L2396.7 4778.9C2403.1 4778.3 2409.2 4775.6 2413.8 4771.2L2462.9 4759.8V4706.1L2405 4669L2345.8 4683.8Z" opacity="0.8" fill="${color}"/>
    <path d="M2451.1 1159.3L2373.2 1177.2L2362 1207.3C2358.2 1207.4 2354.4 1208.2 2350.8 1209.7C2344.7 1212.3 2340 1216.5 2337 1221.5L2327.3 1225.6L2349.3 1277.5L2357.2 1296L2366.9 1291.8C2368.9 1292.3 2370.9 1292.5 2373.1 1292.5C2376.2 1292.5 2379.3 1292 2382.3 1291L2390.5 1308L2467.8 1289.1L2489.6 1238.9L2451.1 1159.3Z" opacity="0.8" fill="${color}"/>
    <path d="M2155.8 697.2L2141.9 734.4C2137.7 736.8 2134.2 740.2 2131.7 744.1C2131.6 744.2 2131.6 744.3 2131.5 744.4L2121.7 748.5L2137.8 786.5L2161.7 791.9H2161.9L2162.2 792.5H2162.3H2162.4L2163.8 791.9L2174.9 792.6L2196.6 786L2204.3 779.7L2210.4 778.3L2226.8 765L2228.8 763.4L2232.5 761.2L2233.9 759.3L2234.8 758.6L2235.9 756.7L2236.4 755.9L2243 746.6L2252.7 726.5L2256.5 726.7L2233.7 679.4L2155.8 697.2Z" opacity="0.8" fill="${color}"/>
`;
  }

  private getHlTunnelOuterSVGHtml(color) {
    return `
    <path d="M2350.7 1209.6C2354.3 1208.1 2358.1 1207.3 2361.9 1207.2L2363.6 1202.7L2184.3 789.8L2174.9 792.6L2163.8 791.9L2162.4 792.5H2162.3H2162.2L2161.9 791.9H2161.7L2151.3 789.5L2338.1 1219.5C2341.2 1215.3 2345.4 1211.9 2350.7 1209.6Z" opacity="0.8" fill="${color}"/>
    <path d="M2346 4700.9L2176 4583L2158.5 4570.9C2156 4569.1 2153 4568.2 2150 4568.2C2145.1 4568.2 2140.5 4570.6 2137.7 4574.7C2135.9 4577.4 2134.9 4580.6 2135.1 4583.9C2135.1 4584 2135.1 4584.1 2135.1 4584.2C2135.4 4588.8 2137.7 4593 2141.5 4595.6L2332.4 4728L2333.5 4727C2334.1 4720.9 2336.9 4715.2 2341.5 4710.8C2342.9 4709.5 2344.5 4708.3 2346.1 4707.2L2346 4700.9Z" opacity="0.8" fill="${color}"/>
    `;
  }

  private getLakeInflowOverlay(): L.SVGOverlay {
    return L.svgOverlay(this.lakeInflowSVGElement, [
      [0, 0],
      this.overlaysService.bounds,
    ]);
  }
  private getLakeOverlay(): L.SVGOverlay {
    return L.svgOverlay(
      this.overlaysService.getOverlay("lake", "All"),
      this.overlaysService.bounds
    );
  }

  private getTunnelOutflowOverlay(): L.SVGOverlay {
    return L.svgOverlay(this.tunnelOutflowSVGElement, [
      [0, 0],
      this.overlaysService.bounds,
    ]);
  }

  private get lakeInflowSVGElement() {
    return this.flowsService.getFlows(
      "#FFF",
      LakeInflow,
      SmallFlowSize,
      ArrowFlowPoints,
      SlowFlowSpeed,
      LongerFlowAnimationBegins
    );
  }

  private get tunnelOutflowSVGElement() {
    return this.flowsService.getFlows(
      "#CE3B3B",
      TunnelOutflow,
      SmallFlowSize,
      ArrowFlowPoints,
      NormalFlowSpeed,
      NormalFlowAnimationBegins
    );
  }
}
