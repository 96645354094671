import { Component, OnInit } from "@angular/core";
import { menuList } from "@data/home-page-menu-list.json";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  public riskAndEffectsMenus = [];

  constructor() {}

  ngOnInit(): void {
    this.riskAndEffectsMenus = menuList;
  }
}
