import { Injectable } from "@angular/core";
import * as L from "leaflet";
import { AreasOfInfluenceLayersService } from "./areas-of-influence-layers.service";

@Injectable({
  providedIn: "root",
})
export class AreasOfInfluenceMapService {
  constructor(private layersService: AreasOfInfluenceLayersService) {}

  public readonly overlays = {
    "Reservoir Option 1 Environmental":
      this.layersService.lakeOption1Environmental,
    "Reservoir Option 2 Environmental":
      this.layersService.lakeOption2Environmental,
    "Reservoir Option 3 Environmental":
      this.layersService.lakeOption3Environmental,
    "Reservoir Option 1 Archaeological":
      this.layersService.lakeOption1EArchaeological,
    "Reservoir Option 2 Archaeological":
      this.layersService.lakeOption2Archaeological,
    "Reservoir Option 3 Archaeological":
      this.layersService.lakeOption3Archaeological,
    "Reservoir Option 1 Recreational":
      this.layersService.lakeOption1Recreational,
    "Reservoir Option 2 Recreational":
      this.layersService.lakeOption2Recreational,
    "Reservoir Option 3 Recreational":
      this.layersService.lakeOption3Recreational,
    "River Option 1 Environmental":
      this.layersService.riverOption1Environmental,
    "River Option 2 Environmental":
      this.layersService.riverOption2Environmental,
    "River Option 3 Environmental":
      this.layersService.riverOption3Environmental,
    "River Option 1 Archaeological":
      this.layersService.riverOption1EArchaeological,
    "River Option 2 Archaeological":
      this.layersService.riverOption2Archaeological,
    "River Option 3 Archaeological":
      this.layersService.riverOption3Archaeological,
    "River Option 1 Recreational": this.layersService.riverOption1Recreational,
    "River Option 2 Recreational": this.layersService.riverOption2Recreational,
    "River Option 3 Recreational": this.layersService.riverOption3Recreational,
    "Littoral Zone Option 1 Environmental":
      this.layersService.littoralZone1Environmental,
    "Littoral Zone Option 2 Environmental":
      this.layersService.littoralZone2Environmental,
    "Littoral Zone Option 3 Environmental":
      this.layersService.littoralZone3Environmental,
    "Dam & Power Station": L.layerGroup([
      this.layersService.dam,
      this.layersService.powerStation,
    ]),
    wave: L.layerGroup([
      this.layersService.lakeBottomFlowOverlay,
      this.layersService.lakeTopFlowOverlay,
      this.layersService.riverTopFlowOverlay,
      this.layersService.riverBottomFlowOverlay,
    ]),
    "Archaeological Option 1": this.layersService.archaeologicalOption1,
    "Archaeological Option 2": this.layersService.archaeologicalOption2,
    "Archaeological Option 3": this.layersService.archaeologicalOption3,
  };

  public readonly options: L.MapOptions = {
    crs: L.CRS.Simple,
    zoomControl: false,
    layers: [
      L.tileLayer("../../assets/tiles/upgrade-effects/{z}/{x}/{y}.png", {
        maxZoom: 6,
        minZoom: 3,
      }),
    ],
    zoom: 0,
    zoomSnap: 0,
    zoomDelta: 0.5,
    center: L.latLng(-44, 80),
  };

  public readonly baseLayers = {
    map: L.tileLayer("../../assets/tiles/upgrade-effects/{z}/{x}/{y}.png", {
      maxZoom: 6,
      minZoom: 3.6,
    }),
  };
}
