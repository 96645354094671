import { Component, ElementRef } from "@angular/core";
import * as L from "leaflet";
import { SystemOverviewMapService } from "@app/pages/system-overview/services/system-overview-map.service";
import { SidebarMenuItem } from "@data/sidebar.menu";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Constants } from "@constants";

@Component({
  selector: "app-system-overview",
  templateUrl: "./system-overview.component.html",
  styleUrls: ["./system-overview.component.scss", "../../../markers.scss", "./markers.scss"],
})
export class SystemOverviewComponent {
  constructor(
    private mapService: SystemOverviewMapService,
    private elementRef: ElementRef
  ) {}

  public map: L.Map;
  public opened: boolean = true;
  public options: L.MapOptions;
  public layersControl: Object;

  public faChevronRight = faChevronRight;

  public ngOnInit() {
    this.options = this.mapService.options;

    this.layersControl = {
      baseLayers: this.mapService.baseLayers,
      overlays: this.mapService.overlays,
    };
  }

  public onMapLoaded(map: L.Map): void {
    this.map = map;
    const southWest = map.unproject([0, 5632], map.getMaxZoom());
    const northEast = map.unproject([10240, 0], map.getMaxZoom());
    map.setMaxBounds(new L.LatLngBounds(southWest, northEast));
    map.on("click", <LeafletMouseEvent>(e) => {
      console.log(`[${e.latlng.lat},${e.latlng.lng}]`);
    });
  }

  public toggleLayer(sidebarMenuItem: SidebarMenuItem) {
    const layer =
      this.layersControl[sidebarMenuItem.control][sidebarMenuItem.text];
    if (this.map.hasLayer(layer)) {
      this.map.removeLayer(layer);
    } else {
      this.map.addLayer(layer);
      const dom: HTMLElement = this.elementRef.nativeElement;
      const elements: NodeListOf<HTMLVideoElement> =
        dom.querySelectorAll(".webm");
      elements.forEach((element) => {
        element.play();
      });
    }
  }

  public readonly resetMap = (): void => {
    this.map.eachLayer((layer) => {
      this.map.removeLayer(layer);
    });
    const map = this.layersControl[Constants.BASE_LAYERS]["map"];
    this.map.addLayer(map);
    this.map.setView([0, 0], 3.75);
  };
}
