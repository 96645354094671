import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Constants } from "@app/constants";
import { SystemRecallInflowSlider } from "@app/types/slider";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AdjustableSliderService } from "./adjustable-slider.service";

@Component({
  selector: "app-adjustable-slider",
  templateUrl: "./adjustable-slider.component.html",
  styleUrls: ["./adjustable-slider.component.scss"],
})
export class AdjustableSliderComponent {
  @Input()
  slider: SystemRecallInflowSlider;

  @Input()
  title: string;

  @Output()
  onOverUpperThresholdStatusChanges = new EventEmitter<boolean>();

  public sliderStyle;
  public thresholdLableTextStyle;
  public thresholdLableHyphenStyle;
  public sliderValue = 0;
  private highRiskColor = Constants.COLORS["risk-level-5"];
  private lowRiskColor = Constants.COLORS["risk-level-1"];
  private ngUnsubscribe = new Subject();

  constructor(private adjustableSliderService: AdjustableSliderService) {}

  ngOnInit() {
    this.sliderValue = this.slider.lowerValue;
    this.onSubscribeResetStatusChanges();
  }

  ngOnChanges(): void {
    this.sliderStyle = this.getSliderStyle(this.slider);
    this.thresholdLableTextStyle = { top: this.slider.pTop };
    this.thresholdLableHyphenStyle = { top: this.slider.hrTop };
    this.onSliderValueChange();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSliderValueChange() {
    const isOverUpperThreshold = this.sliderValue > this.slider?.upperThreshold;
    this.onOverUpperThresholdStatusChanges.emit(isOverUpperThreshold);
  }

  private getSliderStyle(slider: SystemRecallInflowSlider) {
    const filledValue = `${(slider.upperThreshold / slider.ceil) * 100}%`;
    return {
      background: `linear-gradient(90deg, ${this.lowRiskColor} ${filledValue}, ${this.highRiskColor} ${filledValue})`,
    };
  }

  private onSubscribeResetStatusChanges() {
    this.adjustableSliderService.slideIsReset$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isReset) => {
        if (isReset) {
          this.sliderValue = this.slider.lowerValue;
          this.onSliderValueChange()
        }
      });
  }
}
