export const LakeTopFlow: string[] = [
  "M2928.41 1467.32H3279.89",
  "M6164.78 1625.26H6516.26",
  "M7500.55 1508.52L7851.45 1488.23",
  "M7886.31 1687.49L8222.02 1583.38",
  "M5359.02 1517.8L5709.58 1543.32",
  "M5596.04 1648.7L5946.6 1674.22",
  "M6716.35 1711.19H7067.84",
  "M6965.2 1530.64L7306.29 1445.81",
  "M7250.03 1668.77L7600.93 1648.48",
  "M4827.79 1431.63L5170.07 1511.53",
  "M2121.52 1453.39H2473",
  "M3105.14 1327.87L3454.64 1365.22",
];

export const LakeBottomFlow: string[] = [
  "M3014.9 4862.2H3366.4",
  "M5356.1 4941.2H5707.6",
  "M5949 4901.2H6300.5",
  "M6516.3 4904H6867.7",
  "M7135.7 4873.5H7487.2",
  "M7756.8 4843.5H8108.3",
  "M2290.3 4862.2H2641.8",
  "M2663.5 4952.2H3014.9",
];

export const RiverTopFlow: string[] = [
  "M9700.1 1249L9808.48 1335.71L9870.42 1292.36L9916.87 1199.45L9972.61 1211.84L10093.4 1159.19L10183.2 1094.16L10240 1032.23",
  "M9347.07 1183.97H9390.42L9443.07 1227.32L9529.77 1264.48L9700.1 1249",
  "M9102.42 1183.97L9179.84 1249L9294.42 1276.87L9331.58 1249L9347.07 1183.97",
  "M8820.61 1314.03L8925.9 1224.23L9102.42 1183.97",
];
export const RiverBottomFlow: string[] = [
  "M9696.23 4754.26L9800.74 4763.55L10007.5 4732.19H10028.3L10240 4685.74", 
  "M9338.55 4754.26L9438.42 4748.45L9501.13 4754.26L9589.39 4748.45L9696.23 4754.26",
  "M9117.9 4732.19L9195.71 4748.45L9249.13 4732.19L9338.55 4754.26",
  "M8782.29 4748.45L8877.52 4732.19H8927.45H9117.9",
];
