import { Component } from "@angular/core";
import {
  RiskLevelColorForBanner,
  RiskLevelMsgForBanner,
} from "@app/data/risk-map";
import { StatusModelSidePanelImage } from "@app/types/area";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "ts-aoi-modal",
  templateUrl: "./aoi-modal.component.html",
  styleUrls: ["./aoi-modal.component.scss"],
})
export class AOIModalComponent {
  public riskLevel: number;
  public currentImage: string;
  public bannerTitle: string;
  public bannerMessage: RiskLevelMsgForBanner;
  public bannerColor: string;
  public sidePanelImages: StatusModelSidePanelImage[];
  public displaySidePanel: boolean = false;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit() {
    this.bannerMessage = this.getBannerMessage(this.riskLevel);
    this.bannerColor = this.getBannerColor(this.riskLevel);
  }

  public compareRiverFlows() {
    this.displaySidePanel = true;
  }

  public closeSidePanel() {
    this.displaySidePanel = false;
  }

  private getBannerMessage(riskLevel: number): RiskLevelMsgForBanner {
    return riskLevel < 3
      ? RiskLevelMsgForBanner.LowlOrNoImpact
      : riskLevel === 3
      ? RiskLevelMsgForBanner.ModerateImpact
      : RiskLevelMsgForBanner.HighImpact;
  }

  private getBannerColor(riskLevel: number): RiskLevelColorForBanner {
    return riskLevel < 3
      ? RiskLevelColorForBanner.LowlOrNoImpact
      : riskLevel === 3
      ? RiskLevelColorForBanner.ModerateImpact
      : RiskLevelColorForBanner.HighImpact;
  }
}
