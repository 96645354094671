import { MarkerOverlay } from "@app/types/marker-overlay";

export const ArchaeologicalMarkers: MarkerOverlay[] = [
  {
    popupContent: "DhRo-08",
    coordinates: [-31.25,104],
  },
  {
    popupContent: "DhRo-60",
    coordinates: [-29.375, 122],
  },
  {
    popupContent: "DhRo-61",
    coordinates: [-29.25, 123.5],
  },
  {
    popupContent: "DhRo-62",
    coordinates: [-29.375, 121.125],
  },
  {
    popupContent: "DhRo-63",
    coordinates: [-29.625, 132.375],
  },
  {
    popupContent: "DhRo-64",
    coordinates: [-30.625, 128.5],
  },
  {
    popupContent: "DhRo-72",
    coordinates: [-29.25, 121.875],
  },
  {
    popupContent: "DhRo-76",
    coordinates: [-27.5, 133.875],
  },
  {
    popupContent: "DhRo-01",
    coordinates: [-24.875, 72.875],
  },
];

export const RecreationalMarkers: MarkerOverlay[] = [
  {
    popupContent: "AL Boat Launch",
    coordinates: [-26.625, 134.5],
  },
  {
    popupContent: "AL South Beach",
    coordinates: [-25.75, 137.625],
  },
  {
    popupContent: "North Beach",
    coordinates: [-31.625, 102.125],
  },
  {
    popupContent: "North Beach Camp Ground",
    coordinates: [-35.75, 101.75],
  },
  {
    popupContent: "Lower Falls Trail",
    coordinates: [-39.875, 100.875],
  },
  {
    popupContent: "Spirea Nature Trail",
    coordinates: [-24.75, 141.5],
  },
];
