export enum RiskLevel {
  LittelOrNoImpact = "Little/No impact",
  LowImpact = "Low Impact",
  ModerateImpact = "Moderate Impact",
  HighImpact = "High Impact",
  HighestRatedImpact = "Highest Rated Impact",
}

export const RiskMap = {
  1: RiskLevel.LittelOrNoImpact,
  2: RiskLevel.LowImpact,
  3: RiskLevel.ModerateImpact,
  4: RiskLevel.HighImpact,
  5: RiskLevel.HighestRatedImpact,
};

export enum RiskClassName {
  LittelOrNoImpact = "littleNoImpact",
  LowImpact = "lowImpact",
  ModerateImpact = "moderateImpact",
  HighImpact = "highImpact",
  HighestRatedImpact = "highestImpact",
}

export const RiskClassNameMap = {
  1: RiskClassName.LittelOrNoImpact,
  2: RiskClassName.LowImpact,
  3: RiskClassName.ModerateImpact,
  4: RiskClassName.HighImpact,
  5: RiskClassName.HighestRatedImpact,
};

export enum RiskLevelMsgForBanner {
  LowlOrNoImpact = "Low/No impact",
  ModerateImpact = "Moderate Impact",
  HighImpact = "High/Highest Rated Impact",
}

export enum RiskLevelColorForBanner {
  LowlOrNoImpact = "#72CC6C",
  ModerateImpact = "#FFC633",
  HighImpact = "#EC744F",
}
