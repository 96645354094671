import { Injectable } from "@angular/core";
import { MarkerOverlay } from "@app/types/marker-overlay";
import { divIcon, Marker, marker, PopupOptions } from "leaflet";

@Injectable({
  providedIn: "root",
})
export class MarkerOverlayService {
  public getMarker(markerOverlay: MarkerOverlay): Marker {
    const customIcon = divIcon({
      className: "custom-marker",
      iconAnchor: [30.5, 30.5],
      html: this.getMarkerHTMLElement(markerOverlay.color),
      popupAnchor: [-20, -22],
    });
    var customOptions: PopupOptions = {
      closeButton: false,
    };

    const createdMarker = marker(markerOverlay.coordinates, {
      icon: customIcon,
    });
    createdMarker.bindPopup(markerOverlay.popupContent, customOptions);
    createdMarker.on("mouseover", function (e) {
      this.openPopup();
    });
    createdMarker.on("mouseout", function (e) {
      this.closePopup();
    });

    return createdMarker;
  }

  private getMarkerHTMLElement(markerColor: string) {
    return `<svg width="18" height="25" viewBox="0 0 18 25" stroke="#fff" fill="${markerColor}" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.0625 23.5312C8.48438 24.1875 9.46875 24.1875 9.89062 23.5312C16.7344 13.6875 18 12.6562 18 9C18 4.03125 13.9688 0 9 0C3.98438 0 0 4.03125 0 9C0 12.6562 1.21875 13.6875 8.0625 23.5312ZM9 12.75C6.89062 12.75 5.25 11.1094 5.25 9C5.25 6.9375 6.89062 5.25 9 5.25C11.0625 5.25 12.75 6.9375 12.75 9C12.75 11.1094 11.0625 12.75 9 12.75Z" fill="${markerColor}"/>
    </svg>`;
  }
}
