<app-area-categories></app-area-categories>
<div class="legend">
  <div class="legend-header">
    <h3>Reservoir</h3>
    <div>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="risk-level-fill-{{
            influenceAreas?.reservoir.riskLevels[selectedOption]
          }}"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="currentColor"
        />
      </svg>
      <span class="risk-text">{{
        riskMap[influenceAreas?.reservoir.riskLevels[selectedOption]]
      }}</span>
    </div>
  </div>

  <div *ngIf="role ==='Admin'" class="area-table">
    <div
      class="area {{
        area?.riskLevels
          ? 'risk-level-bg-' + area?.riskLevels[selectedOption]
          : ''
      }}"
      *ngFor="let area of influenceAreas?.reservoir.items"
    >
      <span class="legend-marker {{ area.slug }}"></span>
      <span class="content">{{ area.name }}</span>
    </div>
  </div>
</div>
<div class="legend">
  <div class="legend-header">
    <h3>River</h3>
    <div>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="risk-level-fill-{{
            influenceAreas?.river.riskLevels[selectedOption]
          }}"
          cx="7.5"
          cy="7.5"
          r="7.5"
          fill="currentColor"
        />
      </svg>

      <span class="risk-text">{{
        riskMap[influenceAreas?.river.riskLevels[selectedOption]]
      }}</span>
      <svg 
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-photo"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        (click)="showAOIModal()"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="15" y1="8" x2="15.01" y2="8" />
        <rect x="4" y="4" width="16" height="16" rx="3" />
        <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
        <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
      </svg>
    </div>
  </div>
  <div *ngIf="role ==='Admin'" class="area-table">
    <div
      class="area {{
        area?.riskLevels
          ? 'risk-level-bg-' + area?.riskLevels[selectedOption]
          : ''
      }}"
      *ngFor="let area of influenceAreas?.river.items"
    >
      <span class="legend-marker {{ area.slug }}"></span>
      <span class="content">{{ area.name }}</span>
    </div>
  </div>
</div>
<div class="role-btn" (click)="toggleRole()" [innerHTML]="role === 'Guest' ? 'Admin' : 'Guest'"></div>
