import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer/footer.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { RouterModule } from "@angular/router";
import { StatusSliderComponent } from "./status-slider/status-slider.component";
import { LevelSliderComponent } from "./level-slider/level-slider.component";
import { AdjustableSliderComponent } from "./adjustable-slider/adjustable-slider.component";
import { AreaCategoriesComponent } from './area-categories/area-categories.component';
import { AOIModalComponent } from './aoi-modal/aoi-modal.component';
import { AreasComponent } from './areas/areas.component';
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    FooterComponent,
    StatusSliderComponent,
    LevelSliderComponent,
    AdjustableSliderComponent,
    AreaCategoriesComponent,
    AOIModalComponent,
    AreasComponent,
    AOIModalComponent
  ],
  exports: [
    FooterComponent,
    StatusSliderComponent,
    LevelSliderComponent,
    AdjustableSliderComponent,
    AreaCategoriesComponent,
    AreasComponent,
  ],
  imports: [CommonModule, NgxSliderModule, RouterModule, FormsModule],
  providers: [],
})
export class ComponentsModule {}
