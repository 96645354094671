import { Injectable } from "@angular/core";
import { InflowPeriod } from "@app/data/inflow-period";
import { SelectableOptions } from "@app/data/option";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { SystemRecallInflowSlider } from "@app/types/slider";
import { sliders } from "@data/system-recall-inflow-data.json";
import { takeUntil } from "rxjs/operators";
import * as L from "leaflet";
import { OptionsService } from "@app/shared/services/options.service";

@Injectable({
  providedIn: "root",
})
export class SystemRecallMapService {
  public selectedPeriod$: BehaviorSubject<InflowPeriod> = new BehaviorSubject(
    InflowPeriod.OneDay
  );
  public inflowSlider$: BehaviorSubject<SystemRecallInflowSlider> =
    new BehaviorSubject(null);

  constructor(private optionsService: OptionsService) {}

  ngOnInit() {}

  public readonly overlays = {};

  public readonly options: L.MapOptions = {
    crs: L.CRS.Simple,
    zoomControl: false,
    layers: [
      L.tileLayer("../../assets/tiles/upgrade-effects/{z}/{x}/{y}.png", {
        maxZoom: 6,
        minZoom: 3,
      }),
    ],
    zoom: 0,
    zoomSnap: 0,
    zoomDelta: 0.5,
    center: L.latLng(-44, 80),
  };

  public readonly baseLayers = {
    map: L.tileLayer("../../assets/tiles/upgrade-effects/{z}/{x}/{y}.png", {
      maxZoom: 6,
      minZoom: 3.6,
    }),
  };

  public onSubscribeOptionAndPeriodChanges(ngUnsubscribe: Subject<any>) {
    combineLatest(
      this.optionsService.sharedSelectedOption$,
      this.selectedPeriod$
    )
      .pipe(takeUntil(ngUnsubscribe))
      .subscribe(([selectedOption, selectedPeriod]) => {
        const key = this.getKeyForInflowSliders(selectedOption, selectedPeriod);
        const slider = sliders[key];
        this.inflowSlider$.next(slider);
      });
  }

  private getKeyForInflowSliders(
    option: SelectableOptions,
    period: InflowPeriod
  ): string {
    const formattedOption = option.replace(/\s+/g, "_").toLowerCase();
    const formattedPeriod = period.replace(/\s+/g, "_").toLowerCase();
    return [formattedOption, formattedPeriod].join("_and_");
  }

  public getSliderValueForInflow(option: SelectableOptions) {}
}
