export const RainInflowPaths: Array<string> = [
  "M5016.23,2266.29s255.48,529.55,655,585.29",
  "M5490,2094.42s218.32,367,589.94,418.06",
  "M5991.71,2006.16s134.71,120.78,357.68,148.65",
  "M4904.74,2340.61s-23.22,687.49,439,882.58",
  "M5564.35,1439.45s27.88,209,144,334.45",
  "M5829.13,1262.94s74.32,218.32,250.84,343.74",
  "M3794.55,2201.26s55.74,111.48,176.51,162.58",
  "M4008.23,1969s88.25,153.29,241.54,204.39c0,0,51.1,9.29,83.62,69.67",
  "M4154.81,1876.1s83.61,41.8,162.58,148.64S4480,2154.81,4480,2154.81",
  "M4988.35,1634.55s106.84,125.42,106.84,306.58",
  "M2916.61,213.13s75.22,62.53,274.07,139.35c204.38,79,357.67,181.17,357.67,181.17",
  "M3367.19,50.55s116.13,116.13,418.07,134.71",
  "M2833,821.65s41.81,18.58,69.68,97.54,55.74,106.84,120.77,111.49h60.39",
  "M3000.23,542.94s60.38,65,134.71,92.9,125.41,79,125.41,79",
  "M4435.58,32S4393.77,231.71,4194,301.39",
  "M1894.68,830.94s43.41,110.49,144,232.25c88.26,106.84,204.38,148.65,204.38,148.65",
  "M1917.9,403.58s97.55-4.64,157.94,41.81,102.19,0,162.58,74.32S2349.9,608,2349.9,608",
  "M2702.94,529s-97.55,116.13-274.07,232.26",
  "M1941.13,1323.32s106.84,88.26,209,83.62",
  "M176,32S83.06,338.55,468.61,533.65",
  "M36.61,608S69.13,719.45,189.9,775.19",
  "M1355.84,584.74S1012.1,775.19,909.9,844.87c-160.73,109.59-464.51,223-464.51,223",
  "M868.1,515.06S682.29,710.16,431.45,789.13",
  "M1086.42,32S998.16,152.74,830.94,231.71",
  "M3237.13,2243.06s-41.81,311.23,148.64,524.91",
  "M3107.06,2359.19s-69.67,223-301.93,441.29",
  "M6149.65,515.06S6265.77,529,6419.06,765.9C6505.2,899,6925.39,1105,6925.39,1105",
  "M7046.16,129.52s88.26,0,176.52,157.93,334.45,213.68,334.45,213.68",
  "M9466.29,171.32S9489.52,403.58,9772.87,608",
  "M9280.48,208.48s51.1,153.29-111.48,367",
  "M7868.35,180.61s74.33,144,209,213.68",
  "M9624.23,1801.77s74.32,13.94,148.64,139.36S10177,2196.61,10177,2196.61",
  "M8360.74,3961.77s0,325.17,88.26,436.65",
  "M8565.13,3966.42s106.84,367,362.32,427.35",
  "M9080.74,3762s79,464.52,315.87,515.62",
  "M9350.16,3557.65s190.45,83.61,264.78,218.32,181.16,148.64,404.12,181.16",
  "M9977.26,3227.84s18.58,436.64,213.68,571.35",
  "M6196.1,3947.84s79,371.61,329.8,599.22",
  "M6423.71,3659.84s55.74,4.64,120.77,74.32,199.75,102.19,199.75,102.19",
  "M7650,5360s32.52,106.84,120.78,185.8",
  "M7775.45,5206.68s157.94,92.9,209,195.09",
  "M7645.39,3887.45s92.9,204.39,283.35,301.94",
  "M7798.68,3506.55c13.93,9.29,125.42,37.16,195.09,167.22s162.58,139.36,162.58,139.36",
  "M7896.23,3111.71s55.74,116.13,176.51,167.23",
  "M7603.58,2591.45s79,13.94,125.42,97.55,79,102.19,79,102.19",
  "M8281.77,1513.77s60.39,4.65,157.94,88.26,176.52,79,176.52,79",
  "M8105.26,2642.55s74.32-9.29,134.71,46.45,171.87,88.26,171.87,88.26",
  "M8263.19,2280.23s125.42,125.42,306.58,83.61",
  "M8950.68,1509.13s23.22,218.32,97.55,348.39",
  "M8425.77,3190.68s51.1,278.71,199.75,362.32",
  "M8625.52,3083.84s60.38,171.87,255.48,255.48",
  "M8829.9,923.84c0,13.93,134.71,236.9,241.55,315.87",
  "M5016.23,1332.61s65,92.91,167.22,130.07",
  "M8834.55,677.65s-27.87,32.51-116.13,83.61S8690.55,868.1,8537.26,961",
  "M8193.52,1081.77s-148.65,51.1-185.81,274.07",
  "M4895.45,241s-60.39,116.13-288,195.1c0,0-329.8,65-464.51,213.67",
  "M3868.87,1318.68s-65-4.65-185.81,74.32-250.83-9.29-250.83-9.29",
  "M4909.39,1267.58s-46.45,4.65-92.91,51.1-55.74,83.61-120.77,111.48",
  "M5880.23,124.87s32.51,250.84-250.84,436.65",
  "M4983.71,533.65c-4.65,13.93-37.16,278.7,97.55,418.06",
  "M6600.23,45.9c-4.65,13.94-69.68,260.13-199.75,390.2",
  "M4412.35,2549.65s-260.12,246.19-394.83,641",
  "M1425.52,1513.77s120.77,18.58,111.48,130.07c-8.18,98.2,88.26,232.26,185.81,232.26",
  "M1063.19,1708.87s-23.22,162.58,204.39,353",
  "M4468.1,858.81s69.67,134.71,144,134.71,246.19,130.06,246.19,130.06",
  "M4254.42,761.26s-102.19,23.22-162.58,79-171.87,79-171.87,79-51.1,18.58-88.26,92.91",
  "M7571.06,2066.55S7264.48,2224.48,6790.68,2257",
  "M7557.13,1564.87s-55.74,134.71-529.55,260.13",
  "M6999.71,2744.74s-23.23,116.13-190.45,195.1",
  "M7301.65,1184s-51.1,92.9-181.17,171.87",
  "M7036.87,2020.1s-55.74-9.29-144,55.74-181.16,106.84-181.16,106.84",
  "M7761.52,1114.29s51.09,51.1-69.68,116.13-69.68,79-134.71,139.35-288,83.62-367,185.81-153.29,144-153.29,144",
  "M7910.16,1657.77s-55.74,199.75-195.1,255.49",
  "M8393.26,547.58S8342.16,529,8253.9,668.35s-176.51,181.17-339.09,190.46"
]

export const SnowInflowPaths: Array<string> = [
  "M1769.26,1444.1s-18.58,185.8,144,223",
  "M1797.13,464s23.22,264.77,185.81,269.42,301.93,27.87,390.19,97.55",
  "M2712.23,50.55s-27.88,83.61-167.23,185.8",
  "M1142.16,835.58s-27.87,74.32-120.77,106.84c0,0-55.74,27.87-116.13,116.13,0,0-74.32,144-199.74,116.13S450,1258.29,450,1258.29",
  "M1597.39,1053.9l120.77,51.1s297.29,27.87,492.39,204.39",
  "M2610,807.71s-32.51,60.39-181.16,181.16",
  "M4068.61,1397.65s102.2,404.12,464.52,464.51",
  "M1151.45,361.77s-37.16,278.71-264.77,422.71",
  "M50.55,394.29s144,232.26,339.1,260.13",
  "M1388.35,1225.77c0,13.94,51.1,144,144,185.81",
  "M3330,69.13s74.32,283.35,348.39,288",
  "M3664.48,1732.1s-37.16,162.58-376.25,371.61",
  "M3553,2317.39s-32.52,246.19,209,339.09",
  "M5490,1411.58s-65,18.58-134.71,162.58-116.13,125.42-116.13,125.42",
  "M5434.29,942.42s-46.45,236.9,27.87,334.45",
  "M4249.77,1128.23s97.55,190.45,329.81,241.54",
  "M5253.13,315.32s9.29,283.36,199.74,357.68",
  "M6131.06,612.61s9.29,246.2-209,473.81",
  "M6289,789.13s23.23,167.22-106.84,464.52",
  "M4677.13,2521.77s-60.39,613.17,176.52,1221.68",
  "M5364.61,2219.84S5657.26,2596.1,5959.19,2768",
  "M4286.94,2558.94s-232.26,18.58-441.29,204.38-339.1,130.07-339.1,130.07",
  "M7659.32,3664.48s-83.61,0-199.74,55.75-297.29-4.65-297.29-4.65",
  "M8300.35,3980.35s-88.25,46.46-162.58,181.17-83.61,195.09-190.45,297.29",
  "M9229.39,3613.39s32.51,144,139.35,227.61,102.2,9.29,218.32,185.81",
  "M9698.55,2842.29s-32.52,148.65-199.74,241.55",
  "M9498.81,1769.26s-65,227.61-269.42,376.26",
  "M9758.94,2001.52s55.74,55.74,65,171.87,32.51,227.61,153.29,288",
  "M8411.84,1146.81s-97.55,60.38-130.07,232.25",
  "M6999.71,2744.74s-23.23,116.13-190.45,195.1",
  "M7255.19,519.71s-23.22,209,88.26,320.52",
  "M8783.45,106.29s65,102.19-23.22,288",
  "M10139.84,431.45s-60.39,27.87-102.19,106.84-111.49,153.29-111.49,153.29",
  "M9754.29,1109.65s-116.13,46.45-199.74,144S9275.84,1393,9275.84,1393",
  "M8370,1732.1s-83.61,144-241.55,176.51",
  "M7301.65,1184s-51.1,92.9-181.17,171.87",
  "M7036.87,2020.1s-55.74-9.29-144,55.74-181.16,106.84-181.16,106.84",
  "M7761.52,1114.29s51.09,51.1-69.68,116.13-69.68,79-134.71,139.35-288,83.62-367,185.81-153.29,144-153.29,144",
  "M7910.16,1657.77s-55.74,199.75-195.1,255.49",
  "M8393.26,547.58S8342.16,529,8253.9,668.35s-176.51,181.17-339.09,190.46",
  "M7934.94,5033.26s136.25-6.2,353,133.16",
  "M6903.71,5302.68s-142.45-21.68-257,99.09S6429.9,5590.68,6337,5609.26",
  "M7241.26,5250s96,61.94,120.77,353",
  "M7269.13,4141.39s-68.13,49.55-86.71,120.77S7052.35,4448,7052.35,4448",
  "M6083.06,3741.9s192,6.2,278.71,139.36,288,170.32,288,170.32",
  "M6621.9,3314.55c9.29,3.1,154.84,61.93,257,43.35",
  "M3138,1862.16s-102.19-3.1-229.16,123.87-278.71,108.39-278.71,108.39-120.77,3.1-207.48,108.39",
  "M4429.39,273.52s9.29,43.35-229.16,173.42"
]


