<ng-sidebar-container>

  <!-- A sidebar -->
  <ng-sidebar #sidebar [(opened)]="opened" mode="over" dock="true" autoCollapseWidth=500>
    <app-sidebar (onLayerBtnClicked)="toggleLayer($event)" (onMapResetBtnClicked)="resetMap()" (onSidebarCloseClicked)="sidebar.close()"></app-sidebar>
  </ng-sidebar>

  <!-- Page content -->
  <div ng-sidebar-content>
    <fa-icon (click)="sidebar.open()" [icon]="faChevronRight" size="sm" tooltip="Show Sidemenu" class="text-white"></fa-icon>
    <div id="map" leaflet [leafletOptions]="options" (leafletMapReady)="onMapLoaded($event)">
    </div>
  </div>

</ng-sidebar-container>
