import { Injectable } from "@angular/core";
import { SelectableAreaCategories } from "@app/data/area-categories";
import { SelectableOptions } from "@app/data/option";
import { areas } from "@data/areas.json";

@Injectable({
  providedIn: "root",
})
export class AreasOfInfluenceService {
  public getRiverRiskLevel(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions,
    itemName: string
  ): number {
    const area = areas[areaName];
    const item = area[itemName.toLowerCase()];
    return item.riskLevels[selectedOption];
  }
}
