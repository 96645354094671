import { Injectable } from "@angular/core";
import { OverlaysService } from "@app/shared/services/overlays.service";
import * as L from "leaflet";
import { Layer, Marker } from "leaflet";
import { areas } from "@data/areas.json";
import { SelectableAreaCategories } from "@app/data/area-categories";
import { ArchaeologicalMarkers, RecreationalMarkers } from "../data/markers";
import { MarkerOverlayService } from "@app/shared/services/marker-overlay.service";
import { SelectableOptions } from "@app/data/option";
import { Constants } from "@app/constants";
import { MarkerOverlay } from "@app/types/marker-overlay";
import { LabelOverlayService } from "@app/shared/services/label-overlay.service";
import { Labels } from "../data/labels";
import { RiskClassNameMap, RiskMap } from "@app/data/risk-map";
import { AreasOfInfluenceService } from "./areas-of-influence.service";
import { FlowsService } from "@app/shared/services/flows-overlay.service";
import {
  LakeBottomFlow,
  LakeTopFlow,
  RiverBottomFlow,
  RiverTopFlow,
} from "../data/flows";
import {
  ArrowFlowPoints,
  LongerFlowAnimationBegins,
  MediumFlowSize,
  NormalFlowAnimationBegins,
  NormalFlowSpeed,
  SlowFlowSpeed,
  SmallestFlowSize,
  WaveFlowPoints,
} from "@app/data/flow-type";

@Injectable({
  providedIn: "root",
})
export class AreasOfInfluenceLayersService {
  constructor(
    private overlaysService: OverlaysService,
    private markerOverlayService: MarkerOverlayService,
    private labelOverlayService: LabelOverlayService,
    private areasOfInfluenceService: AreasOfInfluenceService,
    private flowsService: FlowsService
  ) {}

  //Lake

  public readonly lakeOption1Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-1", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption1EArchaeological = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-1", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption1Recreational = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-1", Constants.RECREATIONAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption2Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-2", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption2Archaeological = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-2", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption2Recreational = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-2", Constants.RECREATIONAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption3Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-3", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption3Archaeological = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-3", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly lakeOption3Recreational = L.svgOverlay(
    this.overlaysService.getOverlay("lake-option-3", Constants.RECREATIONAL),
    this.overlaysService.bounds
  );

  // River

  public readonly riverOption1Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-1", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly riverOption1EArchaeological = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-1", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly riverOption1Recreational = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-1", Constants.RECREATIONAL),
    this.overlaysService.bounds
  );
  public readonly riverOption2Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-2", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly riverOption2Archaeological = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-2", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly riverOption2Recreational = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-2", Constants.RECREATIONAL),
    this.overlaysService.bounds
  );
  public readonly riverOption3Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-3", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly riverOption3Archaeological = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-3", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly riverOption3Recreational = L.svgOverlay(
    this.overlaysService.getOverlay("river-option-3", Constants.RECREATIONAL),
    this.overlaysService.bounds
  );

  //Littoral Zone

  public readonly littoralZone1Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("littoral-zone-1", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );

  public readonly littoralZone2Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("littoral-zone-2", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );
  public readonly littoralZone3Environmental = L.svgOverlay(
    this.overlaysService.getOverlay("littoral-zone-3", Constants.ENVIRONMENTAL),
    this.overlaysService.bounds
  );

  //Waves
  public readonly lakeBottomFlowOverlay = L.svgOverlay(
    this.lakeBottomFlowSVGElement,
    [
      [0, 0],
      [-88, 160],
    ]
  );

  public readonly lakeTopFlowOverlay = L.svgOverlay(
    this.lakeTopFlowSVGElement,
    [
      [0, 0],
      [-88, 160],
    ]
  );

  public readonly riverTopFlowOverlay = L.svgOverlay(
    this.riverTopFlowSVGElement,
    [
      [0, 0],
      [-88, 160],
    ]
  );

  public readonly riverBottomFlowOverlay = L.svgOverlay(
    this.riverBottomFlowSVGElement,
    [
      [0, 0],
      [-88, 160],
    ]
  );

  public readonly dam: Layer = L.imageOverlay(
    "../../assets/layers/dam.svg",
    this.overlaysService.bounds,
    {
      className: "dam",
    }
  );
  public readonly powerStation: Layer = L.imageOverlay(
    "../../assets/layers/upgrade-tunnel.svg",
    this.overlaysService.bounds
  );
  public readonly archaeologicalOption1 = L.svgOverlay(
    this.overlaysService.getOverlay("option-1", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly archaeologicalOption2 = L.svgOverlay(
    this.overlaysService.getOverlay("option-2", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );
  public readonly archaeologicalOption3 = L.svgOverlay(
    this.overlaysService.getOverlay("option-3", Constants.ARCHAEOLOGICAL),
    this.overlaysService.bounds
  );

  public getLabelsByAreaNameAndOption(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions
  ) {
    const convertedLabels = Labels.map((label) => {
      const riskLevel = this.areasOfInfluenceService.getRiverRiskLevel(
        areaName,
        selectedOption,
        label.lableName
      );
      label.type = RiskMap[riskLevel];
      label.className = RiskClassNameMap[riskLevel];
      return label;
    });
    return this.labelOverlayService.getGroupLables(convertedLabels);
  }

  public getConvertedMarkers(
    markerGroup: MarkerOverlay[],
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions
  ) {
    return markerGroup.map((marker) => {
      const area = areas[areaName];
      const riskLevel = area.reservoir.items.find(
        (item) => item.name === marker.popupContent
      ).riskLevels[selectedOption];
      marker.color = Constants.COLORS[`risk-level-${riskLevel}`];

      return this.markerOverlayService.getMarker(marker);
    });
  }

  public getMarkers(
    areaName: SelectableAreaCategories,
    selectedOption: SelectableOptions
  ): Marker[] {
    if (areaName === SelectableAreaCategories.Archaeological) {
      return this.getConvertedMarkers(
        ArchaeologicalMarkers,
        areaName,
        selectedOption
      );
    } else if (areaName === SelectableAreaCategories.Recreational) {
      return this.getConvertedMarkers(
        RecreationalMarkers,
        areaName,
        selectedOption
      );
    }
    return [];
  }

  private get lakeBottomFlowSVGElement() {
    return this.flowsService.getFlows(
      Constants.COLORS["lake"],
      LakeBottomFlow,
      MediumFlowSize,
      ArrowFlowPoints,
      NormalFlowSpeed,
      NormalFlowAnimationBegins
    );
  }

  private get lakeTopFlowSVGElement() {
    return this.flowsService.getFlows(
      Constants.COLORS["lake"],
      LakeTopFlow,
      MediumFlowSize,
      WaveFlowPoints,
      NormalFlowSpeed,
      NormalFlowAnimationBegins
    );
  }

  private get riverTopFlowSVGElement() {
    return this.flowsService.getFlows(
      Constants.COLORS["lake"],
      RiverTopFlow,
      SmallestFlowSize,
      ArrowFlowPoints,
      SlowFlowSpeed,
      LongerFlowAnimationBegins
    );
  }
  private get riverBottomFlowSVGElement() {
    return this.flowsService.getFlows(
      Constants.COLORS["lake"],
      RiverBottomFlow,
      SmallestFlowSize,
      ArrowFlowPoints,
      SlowFlowSpeed,
      LongerFlowAnimationBegins
    );
  }
}
