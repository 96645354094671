import { Injectable } from "@angular/core";
import { SelectableOptions } from "@app/data/option";
import { BehaviorSubject, Observable } from "rxjs";
import { publishReplay, refCount } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class OptionsService {
  public selectedOption$ = new BehaviorSubject(SelectableOptions.Option1);
  public sharedSelectedOption$: Observable<SelectableOptions>;

  constructor() {
    this.sharedSelectedOption$ = this.selectedOption$.pipe(
      publishReplay(),
      refCount()
    );
  }

  public resetDefaultSelectedOption() {
    this.selectedOption$.next(SelectableOptions.Option1);
  }
}
