import { Component, AfterViewChecked, ChangeDetectorRef } from '@angular/core'
import { slider, fader } from '@settings/animations/route-animations'
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RouterOutlet,
} from '@angular/router'
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  template: `
  <div [@routeAnimations]="prepareRoute(outlet)">
          <router-outlet #outlet="outlet"></router-outlet>
  </div>
`,
  animations: [slider]
})
export class AppComponent implements AfterViewChecked {

  constructor(
    private router: Router,
    protected $gaService: GoogleAnalyticsService,
    private changeDetector: ChangeDetectorRef) {
    router.events.subscribe((event: RouterEvent) => {

      if (event instanceof NavigationEnd) {
        this.$gaService.event('page_path', event.urlAfterRedirects)
      }
    })
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

}
