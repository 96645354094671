import { LablesType } from "@app/data/lables";

export const Labels: LablesType = [
  {
    coordinates: [-23.25,100.125],
    type: "",
    lableName: "Reservoir",
    className: "",
  },
  {
    coordinates: [-74.875,100.125],
    type: "",
    lableName: "Reservoir",
    className: "",
  },
  {
    coordinates: [-19, 140.375],
    type: "",
    lableName: "River",
    className: "",
  },
  {
    coordinates: [-74, 142.25],
    type: "",
    lableName: "River",
    className: "",
  },
];
