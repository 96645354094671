export const LakeInflow: string[] = [
  "M4576.08 426.81L4407.71 533.64L4183.19 912.02L3534.42 1142.16",
  "M4703.54 589.96L4370.55 1113.31L4435.58 1379.07",
  "M4849.92 589.96L4832.14 1223.25",
  "M5429.65 727.83L5095.19 1221.13",
  "M5779.58 840.79L5624.74 1342.47",
  "M6084.61 754.09V974.48L6135.71 1142.16L6084.61 1379.07",
  "M6642.03 679.76L6697.77 853.18L6549.13 1169.05L6725.65 1354.86",
  "M7176.23 665.17L7348.1 974.48L7438.27 1276.38",
  "M7854.42 515.07L7942.68 906.6L7909.06 1184.54",
  "M8393.26 568.28L8212.1 953.76L8166.46 1276.38",
  "M9028.1 679.76L8467.58 1276.38",
  "M9234.03 2023.19L9028.1 1727.45L8616.23 1634.55",
  "M8899.58 2578.74L8747.84 2263.19L8504.74 2066.55L8393.26 1781.64",
  "M8085.64 2679.71L8017 1973.65",
  "M7659.32 2704.48L7524.61 2371.58L7552.48 1828.87",
  "M6906.81 2704.48L6818.55 2376.23L6934.68 1924.1",
  "M5922.03 2704.48L6363.32 2362.18L6426.81 2023.19",
  "M5120 2645.01L5525.65 2363.84L5850.81 2233.77L5894.16 1871.45",
  "M5141.65 2534.16L5188.16 2154.81L5290.29 1846.68",
  "M5095.19 2202.81L4927.87 1942.68L4986.81 1806.42",
  "M5058.02 2614.68L4723.58 2450.55L4598.16 2180.2L4828.09 1698.03",
  "M3854.93 2342.44L4055.25 2185.77L4313.26 2125.39L4497.23 1871.45L4626.03 1541.64",
  "M3738.81 2351.73L3683.07 2089.77V1682.55",
  "M2823.71 2433.41L3097.97 2202.81L3158.16 1698.03",
  "M2675.07 2410.29V2125.39L2735.45 1670.16",
  "M2515.95 2450.55L2244.57 2202.81L2117.64 1698.03",
  "M4319.45 4342.68L4124.35 4588.87L3854.93 4709.65",
  "M4828.09 4268.35L4703.54 4509.9L4458.81 4681.77",
  "M4946.55 4235.84L5058.02 4561L4927.87 4709.65",
  "M5383.19 4268.35L5429.65 4505.26L5262.42 4630.68L5346.03 4709.65",
  "M5861.65 4324.1V4709.65",
  "M6581.65 4412.35L6135.71 4742.16",
  "M6799.97 4412.35L7218.03 4709.65",
  "M7134.42 4342.68L7591.97 4709.65",
  "M7561.77 4300.87C7575.71 4305.52 8017 4709.65 8017 4709.65",
  "M8514.03 4435.58L8374.68 4709.65",
];
