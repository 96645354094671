import { Injectable } from "@angular/core";
import { LablesType } from "@app/data/lables";
import * as L from "leaflet";
import { LayerGroup, Marker } from "leaflet";

@Injectable({
  providedIn: "root",
})
export class LabelOverlayService {
  public readonly getGroupLables = (lables: LablesType): LayerGroup => {
    const markers: Array<Marker> = [];
    for (let lable of lables) {
      markers.push(
        L.marker(lable.coordinates, { opacity: 0.01 }).bindTooltip(
          `<p>${lable.lableName}</p><small>${lable.type}<small>`,
          {
            permanent: true,
            direction: "top",
            interactive: true,
            className: lable.className,
            offset: [-10, 0],
          }
        )
      );
    }
    return L.layerGroup(markers);
  };
}
