<section>
  <img
    class="logo"
    src="../../assets/logos/bchydro-logo-light.png"
    alt="BC Hydro Logo"
    routerLink="/home"
  />
  <img
    class="project-name"
    src="../../assets/images/alouette_hydro_system.svg"
    alt="Alouette Hydro System"
    width="240"
  />
  <a
    class="ts-logo"
    href="https://truescape.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src="../../assets/logos/truescape-logo-light.png"
      alt="Truescape Logo"
    />
  </a>
  <!-- <img src="../../assets/logos/truescape-logo-light.png" alt="Truescape Logo"> -->
</section>
<section class="right-section">
  <div class="row">
    <div class="card" routerLink="/map">
      <div class="image">
        <img loading="lazy" src="../../assets/images/item-bg-300.PNG" />
      </div>
      <div class="content">
        <h3 routerLink="/map">System Overview</h3>
        <p>
          A high level overview of the Alouette Hydro System, surrounding
          districts and influencing factors.
        </p>
      </div>
    </div>
  </div>
  <div class="row items pt-5 pb-3 text-white">
    <h2>Seismic Risks and Effects of Upgrade</h2>
  </div>
  <div class="row">
    <div *ngFor="let menu of riskAndEffectsMenus">
      <div class="card mr-5 mb-5" [routerLink]="menu.routerLink">
        <div class="image">
          <img loading="lazy" [src]="menu.imageSrc" />
        </div>
        <div class="content">
          <h3>{{ menu.title }}</h3>
          <p>
            {{ menu.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
