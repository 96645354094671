import { Component, OnInit } from "@angular/core";
import { SelectableAreaCategories } from "@app/data/area-categories";
import { SelectableOptions } from "@app/data/option";
import {
  RiskLevelColorForBanner,
  RiskLevelMsgForBanner,
  RiskMap,
} from "@app/data/risk-map";
import { Roles } from "@app/data/roles";
import { AuthService } from "@app/services/auth.service";
import { AreasService } from "@app/shared/services/areas.service";
import { OptionsService } from "@app/shared/services/options.service";
import { StatusModelSidePanelImage } from "@app/types/area";
import { areas } from "@data/areas.json";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AOIModalComponent } from "../aoi-modal/aoi-modal.component";

@Component({
  selector: "app-areas",
  templateUrl: "./areas.component.html",
  styleUrls: ["./areas.component.scss"],
})
export class AreasComponent implements OnInit {
  public role: Roles;
  public areaCategory: SelectableAreaCategories;
  public areaCategories: SelectableAreaCategories[];
  public selectableOptions = [];
  public selectedOption = SelectableOptions.Option1;
  public selectedAreaCategory: SelectableAreaCategories;
  public influenceAreas: any;
  public riskMap = RiskMap;

  private ngUnsubscribe = new Subject();
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private areasService: AreasService,
    private optionsService: OptionsService,
    private authService: AuthService
  ) {
    this.areaCategories = Object.values(SelectableAreaCategories);
    this.selectableOptions = Object.values(SelectableOptions);
    this.selectedAreaCategory = this.areaCategories[0];
  }

  ngOnInit(): void {
    this.onSubscribeAreaCategoryChanges();
    this.onSubscribeOptionChanges();
    this.onSubscribeRoleChanges();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onSubscribeAreaCategoryChanges() {
    this.areasService.sharedSelectedAreaCategory$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((areaCategory) => {
        this.areaCategory = areaCategory;
        this.influenceAreas = areas[areaCategory];
      });
  }

  private onSubscribeOptionChanges() {
    this.optionsService.sharedSelectedOption$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((option) => {
        this.selectedOption = option;
      });
  }

  private onSubscribeRoleChanges() {
    this.authService.sharedSelectedRole$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((role) => {
        this.role = role;
      });
  }

  toggleRole() {
    this.role = this.role === Roles.Guest ? Roles.Admin : Roles.Guest;
    this.authService.selectedRole$.next(this.role);
  }
  showAOIModal() {
    const riskLevel = this.getRiverRiskLevel();
    const initialState = {
      bannerTitle: "Alouette South River",
      riskLevel,
      currentImage: this.getImageForDownStream(riskLevel),
      sidePanelImages: this.getSidePanelImagesForDownStreamModel(),
    };
    this.modalRef = this.modalService.show(AOIModalComponent, {
      initialState,
      class: "modal-xl modal-dialog-centered",
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  private getRiverRiskLevel(): number {
    return areas[this.areaCategory].river.riskLevels[this.selectedOption];
  }

  private getImageForDownStream(riskLevel: number) {
    return riskLevel < 3
      ? "downstream-low.jpg"
      : riskLevel === 3
      ? "downstream-moderate.jpg"
      : "downstream-high.jpg";
  }

  private getSidePanelImagesForDownStreamModel(): StatusModelSidePanelImage[] {
    const basicPath = "../../../assets/images/aoi";
    return [
      {
        imagePath: `${basicPath}/downstream-low.jpg`,
        bannerColor: RiskLevelColorForBanner.LowlOrNoImpact,
        bannerText: RiskLevelMsgForBanner.LowlOrNoImpact,
      },
      {
        imagePath: `${basicPath}/downstream-moderate.jpg`,
        bannerColor: RiskLevelColorForBanner.ModerateImpact,
        bannerText: RiskLevelMsgForBanner.ModerateImpact,
      },
      {
        imagePath: `${basicPath}/downstream-high.jpg`,
        bannerColor: RiskLevelColorForBanner.HighImpact,
        bannerText: RiskLevelMsgForBanner.HighImpact,
      },
    ];
  }
}
