export const TunnelOutflow: string[] = [
  "M2094.28 716.079C2094.28 716.079 1993.44 623.079 1779.04 718.689",
  "M2150.06 690.422C2150.06 690.422 2118.71 585.912 2208.28 548.562",
  "M2024.52 619.487C2024.52 619.487 1942.46 527.747 1797.68 521.547",
  "M2079.16 585.997C2079.16 585.997 2058.69 484.227 2095.75 432.797",
  "M2235.82 1397.63L2288.17 1371.55C2312.06 1359.64 2330.44 1338.96 2339.46 1313.84L2344.08 1300.98",
  "M2528.42 1364.29L2492.18 1356.29C2469.91 1351.37 2448.97 1341.69 2430.79 1327.92L2426.54 1324.68L2412.12 1311.82",
  "M2381.77 1405.52L2374.6 1323.06",
  "M2267.45 4846.85L2330.86 4827.07C2359.8 4818.07 2379.86 4804.36 2386.74 4788.9L2390.29 4780.98",
  "M2597.89 4806.31L2529.89 4803.06C2499.67 4801.61 2471.39 4795.71 2449.5 4786.29L2428 4777",
  "M2447.78 4837.25L2415.02 4790.58",
  "M2133.61 4559.55C2133.61 4559.55 2080.51 4521.7 2159.95 4499.55",
  "M2098.64 4553.81C2098.64 4553.81 2016.58 4517.38 1871.8 4514.92",
  "M2089.33 4526.31C2089.33 4526.31 2056.04 4487.31 2086.24 4465.13",
];
